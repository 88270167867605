import React, {useState, useEffect} from 'react';
import {withTranslation} from 'react-i18next';
import {Form, Field} from 'react-final-form';
import {Panel} from "primereact/panel";
import axios from "axios";
import {classNames} from 'primereact/utils';
import {InputText} from 'primereact/inputtext';
import {Button} from 'primereact/button';
import {Dropdown} from "primereact/dropdown";
import _FM_OperatorFormComponent from "../_FM_operator/_FM_OperatorFormComponent";
import {Sidebar} from "primereact/sidebar";
import {InputNumber} from "primereact/inputnumber";

const _FM_AccommodationFormComponent = (props) => {

  const {t} = props;
  const [formData, setFormData] = useState({});
  const [ntakRegisztracioSzam, setNtakRegisztracioSzam] = useState('');
  const [ntakAdatszolgaltatasiAzon, setNtakAdatszolgaltatasiAzon] = useState('');
  const [ntakId, setNtakId] = useState();
  const [countries, setCountries] = useState([]);
  const [operators, setOperators] = useState([]);
  const [loading, setLoading] = useState(false);

  const validate = (data) => {
    let errors = {};
    return errors;
  };
  const onSubmit = (data, form) => {
    setLoading(true)
    if (formData && formData.id) {
      axios.patch(process.env.REACT_APP_API_PROTOCOL + process.env.REACT_APP_API_HOST + "accommodation/" + formData.id,
        formData,
        {
          params: {},
          headers: {
            'Authorization': 'Bearer ' + localStorage.getItem("userToken"),
            "Sys-name": localStorage.getItem('instanceId'),
            "Preferred-locale": localStorage.getItem('language')
          }
        })
        .then(response => {
          setFormData(response.data.data);
          window.App.toastShow('success', t('success'), response.data.message)
          setLoading(false)
          window.App.setState({
            rerenderAccommodationList: true,
            newAccommodationId: response.data.data.id
          })
          if (props.closeForm === true) {
            window.App.setState({
              sidebarAccommodationForm: false,
            })
          }

          if (ntakRegisztracioSzam && ntakAdatszolgaltatasiAzon && ntakId) {
            axios.put(process.env.REACT_APP_API_PROTOCOL + process.env.REACT_APP_API_HOST + "accommodation-detail-ntak/" + ntakId,
              {
                accommodation: formData.id,
                ntak_regisztracio_szam: ntakRegisztracioSzam,
                ntak_adatszolgaltatasi_azon: ntakAdatszolgaltatasiAzon
              },
              {
                params: {},
                headers: {
                  'Authorization': 'Bearer ' + localStorage.getItem("userToken"),
                  "Sys-name": localStorage.getItem('instanceId'),
                  "Preferred-locale": localStorage.getItem('language')
                }
              })
              .then(response => {
                window.App.toastShow('success', t('success'), response.data.message)
              })
              .catch(error => {
                window.App.toastShow('error', t('error'), error.response.data.message, '', error.response)
              })
          }

        })
        .catch(error => {
          setLoading(false)
          window.App.toastShow('error', t('error'), error.response.data.message, '', error.response)
        })
    } else {
      axios.post(process.env.REACT_APP_API_PROTOCOL + process.env.REACT_APP_API_HOST + "accommodation",
        formData,
        {
          params: {},
          headers: {
            'Authorization': 'Bearer ' + localStorage.getItem("userToken"),
            "Sys-name": localStorage.getItem('instanceId'),
            "Preferred-locale": localStorage.getItem('language')
          }
        })
        .then(response => {
          setFormData(response.data.data)
          setLoading(false)
          window.App.toastShow('success', t('success'), response.data.message)
          window.App.setState({
            rerenderAccommodationList: true,
            newAccommodationId: response.data.data.id
          })
          if (props.closeForm === true) {
            window.App.setState({
              sidebarAccommodationForm: false,
            })
          }

          if (ntakRegisztracioSzam && ntakAdatszolgaltatasiAzon) {
            axios.post(process.env.REACT_APP_API_PROTOCOL + process.env.REACT_APP_API_HOST + "accommodation-detail-ntak",
              {
                accommodation: response.data.data.id,
                ntak_regisztracio_szam: ntakRegisztracioSzam,
                ntak_adatszolgaltatasi_azon: ntakAdatszolgaltatasiAzon
              },
              {
                params: {},
                headers: {
                  'Authorization': 'Bearer ' + localStorage.getItem("userToken"),
                  "Sys-name": localStorage.getItem('instanceId'),
                  "Preferred-locale": localStorage.getItem('language')
                }
              })
              .then(response => {
                setNtakId(response.data.data.id)
                window.App.toastShow('success', t('success'), response.data.message)
              })
              .catch(error => {
                window.App.toastShow('error', t('error'), error.response.data.message, '', error.response)
              })
          }

        })
        .catch(error => {
          setLoading(false)
          window.App.toastShow('error', t('error'), error.response.data.message, '', error.response)
        })
    }
    form.restart();
  };
  const isFormFieldValid = (meta) => !!(meta.touched && meta.error);
  const getFormErrorMessage = (meta) => {
    return isFormFieldValid(meta) && <small className="p-error">{meta.error}</small>;
  };

  useEffect(() => {
    if (window.App.state.propsAccommodation && window.App.state.propsAccommodation.id) {
      setLoading(true)
      axios({
        method: 'get',
        url: process.env.REACT_APP_API_PROTOCOL + process.env.REACT_APP_API_HOST + "accommodation/" + window.App.state.propsAccommodation.id,
        params: {},
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem("userToken"),
          "Sys-name": localStorage.getItem('instanceId'),
          "Preferred-locale": localStorage.getItem('language')
        }
      })
        .then(response => {
          setLoading(false)
          setFormData(response.data.data);
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
          setLoading(false)
        })
      axios({
        method: 'get',
        url: process.env.REACT_APP_API_PROTOCOL + process.env.REACT_APP_API_HOST + "accommodation-detail-ntak?search=[accommodation]=" + window.App.state.propsAccommodation.id,
        params: {},
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem("userToken"),
          "Sys-name": localStorage.getItem('instanceId'),
          "Preferred-locale": localStorage.getItem('language')
        }
      })
        .then(response => {
          if (response.data && response.data.data && response.data.data.items && response.data.data.items[0]) {
            if (response.data.data.items[0].id) {
              axios({
                method: 'get',
                url: process.env.REACT_APP_API_PROTOCOL + process.env.REACT_APP_API_HOST + "accommodation-detail-ntak/" + response.data.data.items[0].id,
                params: {},
                headers: {
                  'Authorization': 'Bearer ' + localStorage.getItem("userToken"),
                  "Sys-name": localStorage.getItem('instanceId'),
                  "Preferred-locale": localStorage.getItem('language')
                }
              })
                .then(response => {
                  setNtakId(response.data.data.id)
                  setNtakRegisztracioSzam(response.data.data.ntak_regisztracio_szam)
                  setNtakAdatszolgaltatasiAzon(response.data.data.ntak_adatszolgaltatasi_azon)
                })
                .catch(error => {
                  window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
                })
            }

          }
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
        })
    }
    axios({
      method: 'get',
      url: process.env.REACT_APP_API_PROTOCOL + process.env.REACT_APP_API_HOST + "operator-data",
      params: {},
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem("userToken"),
        "Sys-name": localStorage.getItem('instanceId'),
        "Preferred-locale": localStorage.getItem('language')
      }
    })
      .then(response => {
        setOperators(response.data.data.items);
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
    axios({
      method: 'get',
      url: process.env.REACT_APP_API_PROTOCOL + process.env.REACT_APP_API_HOST + "dictionary/country",
      params: {},
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem("userToken"),
        "Sys-name": localStorage.getItem('instanceId'),
        "Preferred-locale": localStorage.getItem('language')
      }
    })
      .then(response => {
        setCountries(response.data.data);
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
  }, [])

  useEffect(() => {
    setFormData({
      ...formData,
      operator_data: window.App.state.newOperatorId
    })
  }, [window.App.state.newOperatorId])

  useEffect(() => {
    if (formData && formData.operator_data && formData.operator_data.id) {
      setFormData({
        ...formData,
        operator_data: formData.operator_data.id
      })
    }
  }, [formData.operator_data])

  return (
    <Form onSubmit={onSubmit} initialValues={formData}
          validate={validate} render={({handleSubmit}) => (
      <form onSubmit={handleSubmit} className="">
        <Panel className={"p-mt-3"}>
          <div className={"p-grid"}>
            <div className={"p-col"}>
              <h2 className={"p-m-0"}>{t('accommodation_form')}</h2>
            </div>
            <div className={"p-col p-text-right p-col-align-center"}>
              <Button type="submit" aria-label={t('save_and_close')}
                      loading={loading}
                      onClick={() => {
                        props = {...props, closeForm: true}
                      }}
                      className="p-button-success p-ml-2">
              <span>
                <i className={"pi pi-save"}></i>&nbsp;&nbsp;<i className={"pi pi-backward"}></i>
              </span>
              </Button>
              <Button type="submit" aria-label={t('save')} icon={"pi pi-save"}
                      loading={loading}
                      onClick={() => {
                        props = {...props, closeForm: false}
                      }}
                      className="p-button-success p-ml-2"/>
            </div>
          </div>
          <div className={"p-grid"}>
            <div className={"p-col-12 p-lg-6 p-xl-6"}>
              <Field name="name" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <label htmlFor="name"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>{t('name')}</label>
                  <span className="p-d-block">
                      <InputText id="name" {...input}
                                 onChange={(e) => {
                                   setFormData({
                                     ...formData,
                                     name: e.target.value
                                   })
                                 }}
                                 value={formData.name}
                                 className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                    </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
            <div className={"p-col-12 p-lg-6 p-xl-6"}>
              <Field name="short_name" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <label htmlFor="short_name"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>{t('short_name')}</label>
                  <span className="p-d-block">
                      <InputText id="short_name" {...input}
                                 onChange={(e) => {
                                   setFormData({
                                     ...formData,
                                     short_name: e.target.value
                                   })
                                 }}
                                 value={formData.short_name}
                                 className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                    </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
            <div className={"p-col-12 p-lg-6"}>
              <Field name="minimum_reserved_hours" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <label htmlFor="minimum_reserved_hours"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>{t('minimum_reserved_hours')}</label>
                  <span className="p-d-block">
                    <Dropdown optionLabel="label"
                              optionValue="value"
                              options={[
                                {
                                  label: "3 óra",
                                  value: 3,
                                },
                                {
                                  label: "4 óra",
                                  value: 4,
                                },
                                {
                                  label: "24 óra",
                                  value: 24,
                                },
                                {
                                  label: "48 óra",
                                  value: 48,
                                },
                              ]}
                              id={"minimum_reserved_hours"}
                              emptyMessage={"Még nincs beállítás felvéve..."}
                              onChange={(e) => {
                                setFormData({
                                  ...formData,
                                  minimum_reserved_hours: e.value,
                                })

                              }}
                              value={formData.minimum_reserved_hours}
                              showClear
                              className={classNames({'p-invalid': isFormFieldValid(meta)})}
                    />
                    </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
            <div className={"p-col-12 p-lg-6"}>
              <Field name="cleaning_time" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <label htmlFor="cleaning_time"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>{t('cleaning_time')}</label>
                  <span className="p-d-block">
                      <InputNumber id="cleaning_time" {...input}
                                   locale="hu-HU"
                                   onChange={(e) => {
                                     setFormData({
                                       ...formData,
                                       cleaning_time: e.value
                                     })
                                   }}
                                   value={formData.cleaning_time}
                                   className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                    </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>

            <div className={"p-col-12 p-lg-6 p-xl-6"}>
              <Field name="zip_code" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <label htmlFor="zip_code"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>{t('zip_code')}</label>
                  <span className="p-d-block">
                      <InputText id="zip_code" {...input}
                                 onChange={(e) => {
                                   setFormData({
                                     ...formData,
                                     zip_code: e.target.value
                                   })
                                 }}
                                 value={formData.zip_code}
                                 className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                    </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
            <div className={"p-col-12 p-lg-6 p-xl-6"}>
              <Field name="city" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <label htmlFor="city"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>{t('city')}</label>
                  <span className="p-d-block">
                      <InputText id="city" {...input}
                                 onChange={(e) => {
                                   setFormData({
                                     ...formData,
                                     city: e.target.value
                                   })
                                 }}
                                 value={formData.city}
                                 className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                    </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
            <div className={"p-col-12 p-lg-6 p-xl-6"}>
              <Field name="street_and_number" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <label htmlFor="street_and_number"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>{t('street_and_number')}</label>
                  <span className="p-d-block">
                      <InputText id="street_and_number" {...input}
                                 onChange={(e) => {
                                   setFormData({
                                     ...formData,
                                     street_and_number: e.target.value
                                   })
                                 }}
                                 value={formData.street_and_number}
                                 className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                    </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
            <div className={"p-col-12 p-lg-6 p-xl-6"}>
              <Field name="contact_name" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <label htmlFor="contact_name"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>{t('contact_name')}</label>
                  <span className="p-d-block">
                      <InputText id="contact_name" {...input}
                                 onChange={(e) => {
                                   setFormData({
                                     ...formData,
                                     contact_name: e.target.value
                                   })
                                 }}
                                 value={formData.contact_name}
                                 className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                    </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
            <div className={"p-col-12 p-lg-6 p-xl-6"}>
              <Field name="contact_email" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <label htmlFor="contact_email"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>{t('contact_email')}</label>
                  <span className="p-d-block">
                      <InputText id="contact_email" {...input}
                                 onChange={(e) => {
                                   setFormData({
                                     ...formData,
                                     contact_email: e.target.value
                                   })
                                 }}
                                 value={formData.contact_email}
                                 className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                    </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
            <div className={"p-col-12 p-lg-6 p-xl-6"}>
              <Field name="contact_phone" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <label htmlFor="contact_phone"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>{t('contact_phone')}</label>
                  <span className="p-d-block">
                      <InputText id="contact_phone" {...input}
                                 onChange={(e) => {
                                   setFormData({
                                     ...formData,
                                     contact_phone: e.target.value
                                   })
                                 }}
                                 value={formData.contact_phone}
                                 className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                    </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
            <div className={"p-col-12 p-lg-6 p-xl-6"}>
              <Field name="country" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <label htmlFor="country"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>{t('country')}</label>
                  <span className="p-d-block">
                      <Dropdown options={countries}
                                filter
                                optionLabel="name"
                                optionValue="code"
                                id={"country"}
                                emptyMessage={"Még nincs ország..."}
                                onChange={(e) => {
                                  setFormData({
                                    ...formData,
                                    country: e.value
                                  })
                                }}
                                value={formData.country}
                                showClear
                      />
                    </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
            <div className={"p-col-12 p-lg-6 p-xl-6"}>
              <Field name="operator_data" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <label htmlFor="operator_data"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>{t('operator_data')}</label>
                  <span className="p-inputgroup">
                      <Dropdown options={operators}
                                optionLabel="company_name"
                                optionValue="id"
                                id={"operator_data"}
                                emptyMessage={"Még nincs üzemeltető felvéve..."}
                                onChange={(e) => {
                                  setFormData({
                                    ...formData,
                                    operator_data: e.value
                                  })
                                }}
                                value={formData.operator_data}
                                showClear
                      />
                      <Button type="button" className={"p-button"}
                              onClick={() => {
                                window.App.setState({sidebarOperatorForm: true})
                              }}>
                        <i className={"pi pi-window-maximize"}></i>
                      </Button>
                      <Sidebar position="right" visible={window.App.state.sidebarOperatorForm} closeOnEscape={true}
                               style={{width: '780px'}}
                               onHide={() => window.App.setState({
                                 sidebarOperatorForm: false,
                               })}>
                        <_FM_OperatorFormComponent/>
                      </Sidebar>
                    </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
            {formData && formData.country && formData.country === 'HU' &&
            <>
              <div className={"p-col-12"}>
                <hr/>
              </div>
              <div className={"p-col-12 p-lg-6"}>
                <div className="p-field p-fluid">
                  <label htmlFor="ntak_regisztracio_szam">{t('ntak_regisztracio_szam')}</label>
                  <span className="p-d-block">
                      <InputText id="ntak_regisztracio_szam"
                                 onChange={(e) => {
                                   setNtakRegisztracioSzam(e.target.value)
                                 }}
                                 value={ntakRegisztracioSzam}
                      />
                    </span>
                </div>
              </div>
              <div className={"p-col-12 p-lg-6"}>
                <div className="p-field p-fluid">
                  <label htmlFor="ntak_adatszolgaltatasi_azon">{t('ntak_adatszolgaltatasi_azon')}</label>
                  <span className="p-d-block">
                      <InputText id="ntak_adatszolgaltatasi_azon"
                                 onChange={(e) => {
                                   setNtakAdatszolgaltatasiAzon(e.target.value)
                                 }}
                                 value={ntakAdatszolgaltatasiAzon}
                      />
                    </span>
                </div>
              </div>
              <div className={"p-col-12 p-lg-6"}>

              </div>
            </>
            }
          </div>
        </Panel>
      </form>
    )}/>
  )
}
export default withTranslation('common')(_FM_AccommodationFormComponent);
