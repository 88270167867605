import React, {useState, useEffect} from 'react';
import {withTranslation} from 'react-i18next';
import {Form, Field} from 'react-final-form';
import {Panel} from "primereact/panel";
import axios from "axios";
import {classNames} from 'primereact/utils';
import {InputText} from 'primereact/inputtext';
import {Button} from 'primereact/button';
import {Dropdown} from "primereact/dropdown";
import {Sidebar} from "primereact/sidebar";
import {InputNumber} from "primereact/inputnumber";

import _FM_AccommodationFormComponent from "../_FM_accommodation/_FM_AccommodationFormComponent";

const _FM_RescategoryFormComponent = (props) => {

  const {t} = props;
  const [formData, setFormData] = useState({});
  const [accommodation, setAccommodation] = useState([]);
  const [ntakKategoria, setNtakKategoria] = useState([]);
  const [loading, setLoading] = useState(false);

  const validate = (data) => {
    let errors = {};
    return errors;
  };
  const onSubmit = (data, form) => {
    setLoading(true)
    if (formData && formData.id) {
      axios.patch(process.env.REACT_APP_API_PROTOCOL + process.env.REACT_APP_API_HOST + "residential-category/" + formData.id,
        formData,
        {
          params: {},
          headers: {
            'Authorization': 'Bearer ' + localStorage.getItem("userToken"),
            "Sys-name": localStorage.getItem('instanceId'),
            "Preferred-locale": localStorage.getItem('language')
          }
        })
        .then(response => {
          setFormData(response.data.data);
          setLoading(false)
          window.App.toastShow('success', t('success'), response.data.message)
          window.App.setState({
            rerenderRescategoryList: true,
            newRescategoryId: response.data.data.id
          })
          if (props.closeForm === true) {
            window.App.setState({
              sidebarRescategoryForm: false,
            })
          }
        })
        .catch(error => {
          setLoading(false)
          window.App.toastShow('error', t('error'), error.response.data.message, '', error.response)
        })
    } else {
      axios.post(process.env.REACT_APP_API_PROTOCOL + process.env.REACT_APP_API_HOST + "residential-category",
        formData,
        {
          params: {},
          headers: {
            'Authorization': 'Bearer ' + localStorage.getItem("userToken"),
            "Sys-name": localStorage.getItem('instanceId'),
            "Preferred-locale": localStorage.getItem('language')
          }
        })
        .then(response => {
          setFormData(response.data.data)
          setLoading(false)
          window.App.toastShow('success', t('success'), response.data.message)
          window.App.setState({
            rerenderRescategoryList: true,
            newRescategoryId: response.data.data.id
          })
          if (props.closeForm === true) {
            window.App.setState({
              sidebarRescategoryForm: false,
            })
          }
        })
        .catch(error => {
          setLoading(false)
          window.App.toastShow('error', t('error'), error.response.data.message, '', error.response)
        })
    }
    form.restart();
  };
  const isFormFieldValid = (meta) => !!(meta.touched && meta.error);
  const getFormErrorMessage = (meta) => {
    return isFormFieldValid(meta) && <small className="p-error">{meta.error}</small>;
  };

  useEffect(() => {
    if (window.App.state.propsRescategory && window.App.state.propsRescategory.id) {
      setLoading(true)
      axios({
        method: 'get',
        url: process.env.REACT_APP_API_PROTOCOL + process.env.REACT_APP_API_HOST + "residential-category/" + window.App.state.propsRescategory.id,
        params: {},
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem("userToken"),
          "Sys-name": localStorage.getItem('instanceId'),
          "Preferred-locale": localStorage.getItem('language')
        }
      })
        .then(response => {
          setLoading(false)
          setFormData(response.data.data);
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
          setLoading(false)
        })
    }
    axios({
      method: 'get',
      url: process.env.REACT_APP_API_PROTOCOL + process.env.REACT_APP_API_HOST + "dictionary/ntak/kategoria",
      params: {},
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem("userToken"),
        "Sys-name": localStorage.getItem('instanceId'),
        "Preferred-locale": localStorage.getItem('language')
      }
    })
      .then(response => {
        setNtakKategoria(response.data.data);
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
    axios({
      method: 'get',
      url: process.env.REACT_APP_API_PROTOCOL + process.env.REACT_APP_API_HOST + "accommodation",
      params: {},
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem("userToken"),
        "Sys-name": localStorage.getItem('instanceId'),
        "Preferred-locale": localStorage.getItem('language')
      }
    })
      .then(response => {
        setAccommodation(response.data.data.items);
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
  }, [])

  useEffect(() => {
    setFormData({
      ...formData,
      accommodation: window.App.state.newAccommodationId
    })
  }, [window.App.state.newAccommodationId])

  useEffect(() => {
    if (formData && formData.accommodation && formData.accommodation.id) {
      setFormData({
        ...formData,
        accommodation: formData.accommodation.id
      })
    }
  }, [formData.accommodation])

  return (
    <Form onSubmit={onSubmit} initialValues={formData}
          validate={validate} render={({handleSubmit}) => (
      <form onSubmit={handleSubmit} className="">
        <Panel className={"p-mt-3"}>
          <div className={"p-grid"}>
            <div className={"p-col"}>
              <h2 className={"p-m-0"}>{t('rescategory_form')}</h2>
            </div>
            <div className={"p-col p-text-right p-col-align-center"}>
              <Button type="submit" aria-label={t('save_and_close')}
                      loading={loading}
                      onClick={() => {
                        props = {...props, closeForm: true}
                      }}
                      className="p-button-success p-ml-2">
              <span>
                <i className={"pi pi-save"}></i>&nbsp;&nbsp;<i className={"pi pi-backward"}></i>
              </span>
              </Button>
              <Button type="submit" aria-label={t('save')} icon={"pi pi-save"}
                      loading={loading}
                      onClick={() => {
                        props = {...props, closeForm: false}
                      }}
                      className="p-button-success p-ml-2"/>
            </div>
          </div>
          <div className={"p-grid"}>
            <div className={"p-col-12 p-lg-6 p-xl-6"}>
              <Field name="name" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <label htmlFor="name"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>{t('name')}</label>
                  <span className="p-d-block">
                      <InputText id="name" {...input}
                                 onChange={(e) => {
                                   setFormData({
                                     ...formData,
                                     name: e.target.value
                                   })
                                 }}
                                 value={formData.name}
                                 className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                    </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
            <div className={"p-col-12 p-lg-6 p-xl-6"}>
              <Field name="accommodation" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <label htmlFor="accommodation"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>{t('accommodation')}</label>
                  <span className="p-inputgroup">
                      <Dropdown options={accommodation}
                                optionLabel="name"
                                optionValue="id"
                                filter
                                id={"accommodation"}
                                emptyMessage={"Még nincs szálláshely felvéve..."}
                                onChange={(e) => {
                                  setFormData({
                                    ...formData,
                                    accommodation: e.value
                                  })
                                }}
                                value={formData.accommodation}
                                showClear
                      />
                      <Button type="button" className={"p-button"}
                              onClick={() => {
                                window.App.setState({sidebarAccommodationForm: true})
                              }}>
                        <i className={"pi pi-window-maximize"}></i>
                      </Button>
                      <Sidebar position="right" visible={window.App.state.sidebarAccommodationForm} closeOnEscape={true}
                               style={{width: '780px'}}
                               onHide={() => window.App.setState({
                                 sidebarAccommodationForm: false,
                               })}>
                        <_FM_AccommodationFormComponent/>
                      </Sidebar>
                    </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
            <div className={"p-col-12 p-lg-6 p-xl-6"}>
              <Field name="ntak_kategoria" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <label htmlFor="ntak_kategoria"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>{t('ntak_kategoria')}</label>
                  <span className="">
                      <Dropdown options={ntakKategoria}
                                optionLabel="key"
                                optionValue="value"
                                filter
                                id={"ntak_kategoria"}
                                emptyMessage={"Még nincs kategória felvéve..."}
                                onChange={(e) => {
                                  setFormData({
                                    ...formData,
                                    ntak_kategoria: e.value
                                  })
                                }}
                                value={formData.ntak_kategoria}
                                showClear
                      />
                    </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
            <div className={"p-col-12 p-lg-3"}>
              <Field name="number_of_double_beds" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <label htmlFor="number_of_double_beds"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>{t('number_of_double_beds')}</label>
                  <span className="p-d-block">
                      <InputNumber id="number_of_double_beds" {...input}
                                   locale="hu-HU"
                                   onChange={(e) => {
                                     setFormData({
                                       ...formData,
                                       number_of_double_beds: e.value
                                     })
                                   }}
                                   value={formData.number_of_double_beds}
                                   className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                    </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
            <div className={"p-col-12 p-lg-3"}>
              <Field name="number_of_single_beds" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <label htmlFor="number_of_double_beds"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>{t('number_of_single_beds')}</label>
                  <span className="p-d-block">
                      <InputNumber id="number_of_single_beds" {...input}
                                   locale="hu-HU"
                                   onChange={(e) => {
                                     setFormData({
                                       ...formData,
                                       number_of_single_beds: e.value
                                     })
                                   }}
                                   value={formData.number_of_single_beds}
                                   className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                    </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
          </div>
        </Panel>
      </form>
    )}/>
  )
}
export default withTranslation('common')(_FM_RescategoryFormComponent);
