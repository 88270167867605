import React, {useState, useEffect} from 'react';
import {withTranslation} from 'react-i18next';
import {Form, Field} from 'react-final-form';
import {Panel} from "primereact/panel";
import axios from "axios";
import {classNames} from 'primereact/utils';
import {InputText} from 'primereact/inputtext';
import {Button} from 'primereact/button';
import {InputSwitch} from "primereact/inputswitch";

const _FM_OperatorFormComponent = (props) => {

  const {t} = props;
  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState(false);

  const validate = (data) => {
    let errors = {};
    return errors;
  };
  const onSubmit = (data, form) => {
    setLoading(true)
    if (formData && formData.id) {
      axios.patch(process.env.REACT_APP_API_PROTOCOL + process.env.REACT_APP_API_HOST + "operator-data/" + formData.id,
        formData,
        {
          params: {},
          headers: {
            'Authorization': 'Bearer ' + localStorage.getItem("userToken"),
            "Sys-name": localStorage.getItem('instanceId'),
            "Preferred-locale": localStorage.getItem('language')
          }
        })
        .then(response => {
          setFormData(response.data.data);
          setLoading(false)
          window.App.toastShow('success', t('success'), response.data.message)
          window.App.setState({
            rerenderOperatorList: true,
            newOperatorId: response.data.data.id
          })
          if (props.closeForm === true) {
            window.App.setState({
              sidebarOperatorForm: false,
            })
          }
        })
        .catch(error => {
          setLoading(false)
          window.App.toastShow('error', t('error'), error.response.data.message, '', error.response)
        })
    } else {
      axios.post(process.env.REACT_APP_API_PROTOCOL + process.env.REACT_APP_API_HOST + "operator-data",
        formData,
        {
          params: {},
          headers: {
            'Authorization': 'Bearer ' + localStorage.getItem("userToken"),
            "Sys-name": localStorage.getItem('instanceId'),
            "Preferred-locale": localStorage.getItem('language')
          }
        })
        .then(response => {
          setFormData(response.data.data)
          setLoading(false)
          window.App.toastShow('success', t('success'), response.data.message)
          window.App.setState({
            rerenderOperatorList: true,
            newOperatorId: response.data.data.id
          })
          if (props.closeForm === true) {
            window.App.setState({
              sidebarOperatorForm: false,
            })
          }
        })
        .catch(error => {
          setLoading(false)
          window.App.toastShow('error', t('error'), error.response.data.message, '', error.response)
        })
    }
    form.restart();
  };
  const isFormFieldValid = (meta) => !!(meta.touched && meta.error);
  const getFormErrorMessage = (meta) => {
    return isFormFieldValid(meta) && <small className="p-error">{meta.error}</small>;
  };

  useEffect(() => {
    if (window.App.state.propsOperator && window.App.state.propsOperator.id) {
      setLoading(true)
      axios({
        method: 'get',
        url: process.env.REACT_APP_API_PROTOCOL + process.env.REACT_APP_API_HOST + "operator-data/" + window.App.state.propsOperator.id,
        params: {},
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem("userToken"),
          "Sys-name": localStorage.getItem('instanceId'),
          "Preferred-locale": localStorage.getItem('language')
        }
      })
        .then(response => {
          setLoading(false)
          setFormData(response.data.data);
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
          setLoading(false)
        })
    }
  }, [])

  return (
    <Form onSubmit={onSubmit} initialValues={formData}
          validate={validate} render={({handleSubmit}) => (
      <form onSubmit={handleSubmit} className="">
        <Panel className={"p-mt-3"}>
          <div className={"p-grid"}>
            <div className={"p-col"}>
              <h2 className={"p-m-0"}>{t('operator_form')}</h2>
            </div>
            <div className={"p-col p-text-right p-col-align-center"}>
              <Button type="submit" aria-label={t('save_and_close')}
                      loading={loading}
                      onClick={() => {
                        props = {...props, closeForm: true}
                      }}
                      className="p-button-success p-ml-2">
              <span>
                <i className={"pi pi-save"}></i>&nbsp;&nbsp;<i className={"pi pi-backward"}></i>
              </span>
              </Button>
              <Button type="submit" aria-label={t('save')} icon={"pi pi-save"}
                      loading={loading}
                      onClick={() => {
                        props = {...props, closeForm: false}
                      }}
                      className="p-button-success p-ml-2"/>
            </div>
          </div>
          <div className={"p-grid"}>
            <div className={"p-col-12 p-lg-6 p-xl-6"}>
              <Field name="company_name" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <label htmlFor="company_name"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>{t('company_name')}</label>
                  <span className="p-d-block">
                      <InputText id="company_name" {...input}
                                 onChange={(e) => {
                                   setFormData({
                                     ...formData,
                                     company_name: e.target.value
                                   })
                                 }}
                                 value={formData.company_name}
                                 className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                    </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
            <div className={"p-col-12 p-lg-6 p-xl-6"}>
              <Field name="tax_number" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <label htmlFor="tax_number"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>{t('tax_number')}</label>
                  <span className="p-d-block">
                      <InputText id="tax_number" {...input}
                                 onChange={(e) => {
                                   setFormData({
                                     ...formData,
                                     tax_number: e.target.value
                                   })
                                 }}
                                 value={formData.tax_number}
                                 className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                    </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
            <div className={"p-col-12 p-lg-6 p-xl-6"}>
              <Field name="zip_code" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <label htmlFor="zip_code"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>{t('zip_code')}</label>
                  <span className="p-d-block">
                      <InputText id="zip_code" {...input}
                                 onChange={(e) => {
                                   setFormData({
                                     ...formData,
                                     zip_code: e.target.value
                                   })
                                 }}
                                 value={formData.zip_code}
                                 className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                    </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
            <div className={"p-col-12 p-lg-6 p-xl-6"}>
              <Field name="registered_office_city" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <label htmlFor="registered_office_city"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>{t('registered_office_city')}</label>
                  <span className="p-d-block">
                      <InputText id="registered_office_city" {...input}
                                 onChange={(e) => {
                                   setFormData({
                                     ...formData,
                                     registered_office_city: e.target.value
                                   })
                                 }}
                                 value={formData.registered_office_city}
                                 className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                    </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
            <div className={"p-col-12 p-lg-6 p-xl-6"}>
              <Field name="street_and_house_number" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <label htmlFor="street_and_house_number"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>{t('street_and_house_number')}</label>
                  <span className="p-d-block">
                      <InputText id="street_and_house_number" {...input}
                                 onChange={(e) => {
                                   setFormData({
                                     ...formData,
                                     street_and_house_number: e.target.value
                                   })
                                 }}
                                 value={formData.street_and_house_number}
                                 className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                    </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
            <div className={"p-col-12 p-lg-6 p-xl-6"}>
              <Field name="vat_payment" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <label htmlFor="vat_payment"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>{t('vat_payment')}</label>
                  <span className="p-d-block">
                      <InputSwitch
                        className={"p-mt-1"}
                        checked={formData.vat_payment}
                        value={formData.vat_payment}
                        onChange={e => setFormData({...formData, vat_payment: e.target.value})}/>
                    </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
          </div>
        </Panel>

      </form>
    )}/>
  )
}
export default withTranslation('common')(_FM_OperatorFormComponent);
