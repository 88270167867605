import React, {useState, useEffect} from 'react';
import {withTranslation} from 'react-i18next';
import {Form, Field} from 'react-final-form';
import {Panel} from "primereact/panel";
import axios from "axios";
import {classNames} from 'primereact/utils';
import {InputText} from 'primereact/inputtext';
import {Button} from 'primereact/button';
import {Dropdown} from "primereact/dropdown";
import {Sidebar} from "primereact/sidebar";

const _FM_PaymentmethodFormComponent = (props) => {

  const {t, i18n} = props;
  const [formData, setFormData] = useState({});
  const [ntakFizetesiMod, setNtakFizetesiMod] = useState([]);
  const [loading, setLoading] = useState(false);

  const validate = (data) => {
    let errors = {};
    return errors;
  };
  const onSubmit = (data, form) => {
    setLoading(true)
    if (formData && formData.id) {
      axios.patch(process.env.REACT_APP_API_PROTOCOL + process.env.REACT_APP_API_HOST + "payment-method/" + formData.id,
        formData,
        {
          params: {},
          headers: {
            'Authorization': 'Bearer ' + localStorage.getItem("userToken"),
            "Sys-name": localStorage.getItem('instanceId'),
            "Preferred-locale": localStorage.getItem('language')
          }
        })
        .then(response => {
          setFormData(response.data.data);
          setLoading(false)
          window.App.toastShow('success', t('success'), response.data.message)
          window.App.setState({
            rerenderRescategoryList: true,
            newRescategoryId: response.data.data.id
          })
          if (props.closeForm === true) {
            window.App.setState({
              sidebarRescategoryForm: false,
            })
          }
        })
        .catch(error => {
          setLoading(false)
          window.App.toastShow('error', t('error'), error.response.data.message, '', error.response)
        })
    } else {
      axios.post(process.env.REACT_APP_API_PROTOCOL + process.env.REACT_APP_API_HOST + "payment-method",
        formData,
        {
          params: {},
          headers: {
            'Authorization': 'Bearer ' + localStorage.getItem("userToken"),
            "Sys-name": localStorage.getItem('instanceId'),
            "Preferred-locale": localStorage.getItem('language')
          }
        })
        .then(response => {
          setFormData(response.data.data)
          setLoading(false)
          window.App.toastShow('success', t('success'), response.data.message)
          window.App.setState({
            rerenderRescategoryList: true,
            newRescategoryId: response.data.data.id
          })
          if (props.closeForm === true) {
            window.App.setState({
              sidebarRescategoryForm: false,
            })
          }
        })
        .catch(error => {
          setLoading(false)
          window.App.toastShow('error', t('error'), error.response.data.message, '', error.response)
        })
    }
    form.restart();
  };
  const isFormFieldValid = (meta) => !!(meta.touched && meta.error);
  const getFormErrorMessage = (meta) => {
    return isFormFieldValid(meta) && <small className="p-error">{meta.error}</small>;
  };

  useEffect(() => {
    if (window.App.state.propsPaymentmethod && window.App.state.propsPaymentmethod.id) {
      setLoading(true)
      axios({
        method: 'get',
        url: process.env.REACT_APP_API_PROTOCOL + process.env.REACT_APP_API_HOST + "payment-method/" + window.App.state.propsPaymentmethod.id,
        params: {},
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem("userToken"),
          "Sys-name": localStorage.getItem('instanceId'),
          "Preferred-locale": localStorage.getItem('language')
        }
      })
        .then(response => {
          setLoading(false)
          setFormData(response.data.data);
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
          setLoading(false)
        })
    }
    axios({
      method: 'get',
      url: process.env.REACT_APP_API_PROTOCOL + process.env.REACT_APP_API_HOST + "dictionary/ntak/fizetesi-mod",
      params: {},
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem("userToken"),
        "Sys-name": localStorage.getItem('instanceId'),
        "Preferred-locale": localStorage.getItem('language')
      }
    })
      .then(response => {
        setNtakFizetesiMod(response.data.data);
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
  }, [])

  useEffect(() => {
    if (formData && formData.paymentmethod && formData.paymentmethod.id) {
      setFormData({
        ...formData,
        paymentmethod: formData.paymentmethod.id
      })
    }
  }, [formData.paymentmethod])

  return (
    <Form onSubmit={onSubmit} initialValues={formData}
          validate={validate} render={({handleSubmit}) => (
      <form onSubmit={handleSubmit} className="">
        <Panel className={"p-mt-3"}>
          <div className={"p-grid"}>
            <div className={"p-col"}>
              <h2 className={"p-m-0"}>{t('paymentmethod_form')}</h2>
            </div>
            <div className={"p-col p-text-right p-col-align-center"}>
              <Button type="submit" aria-label={t('save_and_close')}
                      loading={loading}
                      onClick={() => {
                        props = {...props, closeForm: true}
                      }}
                      className="p-button-success p-ml-2">
              <span>
                <i className={"pi pi-save"}></i>&nbsp;&nbsp;<i className={"pi pi-backward"}></i>
              </span>
              </Button>
              <Button type="submit" aria-label={t('save')} icon={"pi pi-save"}
                      loading={loading}
                      onClick={() => {
                        props = {...props, closeForm: false}
                      }}
                      className="p-button-success p-ml-2"/>
            </div>
          </div>
          <div className={"p-grid"}>
            <div className={"p-col-12 p-lg-6 p-xl-6"}>
              <Field name="name" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <label htmlFor="name"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>{t('name')}</label>
                  <span className="p-d-block">
                      <InputText id="name" {...input}
                                 onChange={(e) => {
                                   setFormData({
                                     ...formData,
                                     name: e.target.value
                                   })
                                 }}
                                 value={formData.name}
                                 className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                    </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
            <div className={"p-col-12 p-lg-6 p-xl-6"}>
              <Field name="ntak_fizetesi_mod" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <label htmlFor="ntak_fizetesi_mod"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>{t('ntak_fizetesi_mod')}</label>
                  <span className="p-d-block">
                      <Dropdown options={ntakFizetesiMod}
                                filter
                                optionLabel="key"
                                optionValue="value"
                                id={"ntak_fizetesi_mod"}
                                emptyMessage={"Még nincs fizetési mód..."}
                                onChange={(e) => {
                                  setFormData({
                                    ...formData,
                                    ntak_fizetesi_mod: e.value
                                  })
                                }}
                                value={formData.ntak_fizetesi_mod}
                                showClear
                      />
                    </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
          </div>
        </Panel>
      </form>
    )}/>
  )
}
export default withTranslation('common')(_FM_PaymentmethodFormComponent);
