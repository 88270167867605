import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {I18nextProvider} from 'react-i18next';
import i18next from 'i18next';

let common_en = require('./translations/en/common.json');
let common_hu = require('./translations/hu/common.json');
if(process.env.REACT_APP_BRAND === 'felhomatrac') {
  common_hu = require('./translations/hu/common-felhomatrac.json');
}

i18next.init({
  interpolation: {escapeValue: false},
  lng: 'hu',
  resources: {
    en: {
      common: common_en
    },
    hu: {
      common: common_hu
    }
  },
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.Fragment>
    <I18nextProvider i18n={i18next}>
      <App/>
    </I18nextProvider>
  </React.Fragment>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
