import React, {useState, useEffect, useRef} from 'react';
import {Button} from "primereact/button";
import {Link} from "react-router-dom";
import {Menubar} from "primereact/menubar";
import {withTranslation} from 'react-i18next';
import LangselectComponent from "./LangselectComponent";
import {SplitButton} from "primereact/splitbutton";

const NavbarComponent = (props) => {

  const {t, i18n} = props;
  const [menu, setMenu] = useState([]);

  useEffect(() => {
    let _menu = [
      {
        label: t('control_panel'),
        icon: 'pi pi-sliders-v',
        url: '/#/control-panel',
        className: 'control-panel'
      }
    ]
    if (process.env.REACT_APP_BRAND === 'felhomatrac') {
      _menu.push(
        {
          label: t('calendar'),
          icon: 'pi pi-calendar',
          url: '/#/calendar',
          className: '_FM_calendar'
        },
        {
          label: t('reservations'),
          icon: 'pi pi-check-square',
          url: '/#/reservation',
          className: '_FM_reservation'
        },

        {
          label: t('master_data'),
          icon: 'pi pi-box',
          className: '_FM_master-data',
          items: [
            {
              label: t('services'),
              icon: 'pi pi-box',
              url: '/#/service',
              className: '_FM_master-data'
            },
            {
              label: t('partners'),
              icon: 'pi pi-user',
              url: '/#/partner',
              className: 'partner'
            },
            {
              label: t('builder'),
              icon: 'pi pi-sitemap',
              url: '/#/builder',
              className: '_FM_master-data highlighted'
            },
            {
              label: t('buildings'),
              icon: 'pi pi-building',
              url: '/#/building',
              className: '_FM_master-data'

            },
            {
              label: t('rescategories'),
              icon: 'pi pi-star',
              url: '/#/category',
              className: '_FM_master-data'
            },
            {
              label: t('resunits'),
              icon: 'pi pi-home',
              url: '/#/unit',
              className: '_FM_master-data'
            },
            {
              label: t('berths'),
              icon: 'pi pi-inbox',
              url: '/#/berth',
              className: '_FM_master-data'
            },
            {
              label: t('ooo_resunits'),
              icon: 'pi pi-inbox',
              url: '/#/oooresunit',
              className: '_FM_master-data'
            },
            {
              label: t('ooo_berths'),
              icon: 'pi pi-inbox',
              url: '/#/oooberth',
              className: '_FM_master-data'
            },
            {
              label: t('payment_methods'),
              icon: 'pi pi-credit-card',
              url: '/#/payment-method',
              className: '_FM_master-data'
            },
            {
              label: t('reservation_chanels'),
              icon: 'pi pi-wifi',
              url: '/#/chanel',
              className: '_FM_master-data'
            },
          ]
        },
      )
    }
    setMenu(_menu)
  }, [])

  return (
    <nav>
      <>
        <Menubar
          style={{minHeight: '52px'}}
          start={
            <>
              <Link to={"/"}>
                <img src={require(".." + process.env.REACT_APP_LOGO_FILE)} className={"p-ml-2 p-mt-2 p-mb-0"}/>
                <span className={"p-d-block p-mb-2 p-ml-2"}>{localStorage.getItem("instanceName")}</span>
              </Link>
            </>
          }
          model={menu}
          className={"p-py-0"}
          end={
            <>
              <SplitButton label={t('profile')}
                           icon="pi pi-user"
                           className={"p-button-success p-ml-2"}
                           onClick={() => {

                           }}
                           model={[
                             {
                               label: t('profile'),
                               icon: 'pi pi-user',
                               command: () => {
                                 window.location.replace('/#/profile');
                                 window.App.forceUpdate()
                               }
                             },
                             {
                               label: t('users'),
                               icon: 'pi pi-users',
                               command: () => {
                                 window.location.replace('/#/user');
                                 window.App.forceUpdate()
                               }
                             },
                             {
                               label: t('operators'),
                               icon: 'pi pi-briefcase',
                               command: () => {
                                 window.location.replace('/#/operator');
                                 window.App.forceUpdate()
                               }
                             },
                             {
                               label: t('accommodations'),
                               icon: 'pi pi-home',
                               command: () => {
                                 window.location.replace('/#/accommodation');
                                 window.App.forceUpdate()
                               }
                             },
                             {
                               label: t('logout'),
                               icon: 'pi pi-sign-out',
                               command: () => {
                                 if (window.confirm(t('confirm_logout'))) {
                                   window.App.logout()
                                 }
                               }
                             },
                           ]}/>
              <LangselectComponent/>
            </>
          }
        />
      </>
    </nav>
  )
}
export default withTranslation('common')(NavbarComponent);
