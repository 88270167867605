import React, {useState, useEffect, useRef} from 'react';
import {withTranslation} from 'react-i18next';
import {Panel} from "primereact/panel";
import {Sidebar} from "primereact/sidebar";

import _FM_ResunitListComponent from "../components/_FM_resunit/_FM_ResunitListComponent";
import _FM_ResunitFormComponent from "../components/_FM_resunit/_FM_ResunitFormComponent";

const _FM_AccomodatonPage = (props) => {

  const {t, i18n} = props;

  useEffect(() => {
    document.title = t('resunit') + ' - ' + process.env.REACT_APP_TITLE;
  }, []);

  return (
    <div className="_FM_ResunitPage p-component p-m-lg-4">
      <Panel>
        <_FM_ResunitListComponent />
      </Panel>
      <Sidebar position="right" visible={window.App.state.sidebarResunitForm} closeOnEscape={true}
               style={{width: '780px'}}
               onHide={() => window.App.setState({
                 sidebarResunitForm: false,
               })}>
        <_FM_ResunitFormComponent />
      </Sidebar>
    </div>
  )
}
export default withTranslation('common')(_FM_AccomodatonPage);
