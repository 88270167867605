import React, {useState, useEffect, useRef} from 'react';
import {withTranslation} from 'react-i18next';
import {Panel} from "primereact/panel";
import {Sidebar} from "primereact/sidebar";

import _FM_ReschanelListComponent from "../components/_FM_reschanel/_FM_ReschanelListComponent";
import _FM_ReschanelFormComponent from "../components/_FM_reschanel/_FM_ReschanelFormComponent";

const _FM_ReschanelPage = (props) => {

  const {t, i18n} = props;

  useEffect(() => {
    document.title = t('reservation_chanel') + ' - ' + process.env.REACT_APP_TITLE;
  }, []);

  return (
    <div className="_FM_ReschanelPage p-component p-m-lg-4">
      <Panel>
        <_FM_ReschanelListComponent />
      </Panel>
      <Sidebar position="right" visible={window.App.state.sidebarReschanelForm} closeOnEscape={true}
               style={{width: '780px'}}
               onHide={() => window.App.setState({
                 sidebarReschanelForm: false,
               })}>
        <_FM_ReschanelFormComponent />
      </Sidebar>
    </div>
  )
}
export default withTranslation('common')(_FM_ReschanelPage);
