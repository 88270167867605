import React, {useState, useEffect} from 'react';
import {withTranslation} from 'react-i18next';
import {Form, Field} from 'react-final-form';
import {Panel} from "primereact/panel";
import axios from "axios";
import {classNames} from 'primereact/utils';
import {Button} from 'primereact/button';
import {Dropdown} from "primereact/dropdown";
import {InputMask} from "primereact/inputmask";
import {Calendar} from "primereact/calendar";
import moment from "moment";
import {InputText} from "primereact/inputtext";
import {addLocale} from "primereact/api";

const _FM_ReservationFormComponent = (props) => {

  const {t} = props;
  const [formData, setFormData] = useState({});
  const [allday, setAllday] = useState(false);
  const [unit, setUnit] = useState([]);
  const [berth, setBerth] = useState([]);
  const [loading, setLoading] = useState(false);

  const validate = (data) => {
    let errors = {};
    return errors;
  };
  const onSubmit = (data, form) => {
    setLoading(true)
    if (formData && formData.id) {
      axios.patch(process.env.REACT_APP_API_PROTOCOL + process.env.REACT_APP_API_HOST + "reservation/" + formData.id,
        formData,
        {
          params: {},
          headers: {
            'Authorization': 'Bearer ' + localStorage.getItem("userToken"),
            "Sys-name": localStorage.getItem('instanceId'),
            "Preferred-locale": localStorage.getItem('language')
          }
        })
        .then(response => {
          setFormData(response.data.data);
          setLoading(false)
          window.App.toastShow('success', t('success'), response.data.message)
          window.App.setState({
            rerenderReservationList: true,
            newReservationId: response.data.data.id
          })
          if (props.closeForm === true) {
            window.App.setState({
              sidebarReservationForm: false,
            })
          }
        })
        .catch(error => {
          setLoading(false)
          window.App.toastShow('error', t('error'), error.response.data.message, '', error.response)
        })
    } else {
      axios.post(process.env.REACT_APP_API_PROTOCOL + process.env.REACT_APP_API_HOST + "reservation",
        formData,
        {
          params: {},
          headers: {
            'Authorization': 'Bearer ' + localStorage.getItem("userToken"),
            "Sys-name": localStorage.getItem('instanceId'),
            "Preferred-locale": localStorage.getItem('language')
          }
        })
        .then(response => {
          setFormData(response.data.data)
          setLoading(false)
          window.App.toastShow('success', t('success'), response.data.message)
          window.App.setState({
            rerenderReservationList: true,
            newReservationId: response.data.data.id
          })
          if (props.closeForm === true) {
            window.App.setState({
              sidebarReservationForm: false,
            })
          }
        })
        .catch(error => {
          setLoading(false)
          window.App.toastShow('error', t('error'), error.response.data.message, '', error.response)
        })
    }
    form.restart();
  };
  const isFormFieldValid = (meta) => !!(meta.touched && meta.error);
  const getFormErrorMessage = (meta) => {
    return isFormFieldValid(meta) && <small className="p-error">{meta.error}</small>;
  };

  const checkAllday = (unitId) => {
    axios({
      method: 'get',
      url: process.env.REACT_APP_API_PROTOCOL + process.env.REACT_APP_API_HOST + "residential-unit/" + unitId,
      params: {},
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem("userToken"),
        "Sys-name": localStorage.getItem('instanceId'),
        "Preferred-locale": localStorage.getItem('language')
      }
    })
      .then(response => {
        if (response.data && response.data.data && response.data.data.residential_category) {
          axios({
            method: 'get',
            url: process.env.REACT_APP_API_PROTOCOL + process.env.REACT_APP_API_HOST + "residential-category/" + response.data.data.residential_category.id,
            params: {},
            headers: {
              'Authorization': 'Bearer ' + localStorage.getItem("userToken"),
              "Sys-name": localStorage.getItem('instanceId'),
              "Preferred-locale": localStorage.getItem('language')
            }
          })
            .then(response => {
              if (response.data && response.data.data && response.data.data.accommodation) {
                axios({
                  method: 'get',
                  url: process.env.REACT_APP_API_PROTOCOL + process.env.REACT_APP_API_HOST + "accommodation/" + response.data.data.accommodation.id,
                  params: {},
                  headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem("userToken"),
                    "Sys-name": localStorage.getItem('instanceId'),
                    "Preferred-locale": localStorage.getItem('language')
                  }
                })
                  .then(response => {
                    if (response.data && response.data.data && response.data.data.minimum_reserved_hours && (response.data.data.minimum_reserved_hours === 48 || response.data.data.minimum_reserved_hours === 24)) {
                      setAllday(true)
                    } else {
                      setAllday(false)
                    }
                  })
                  .catch(error => {
                    window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
                  })
              }
            })
            .catch(error => {
              window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
            })
        }
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })

  }

  addLocale('default', {
    firstDayOfWeek: 1,
    dayNames: [t('sunday'), t('monday'), t('tuesday'), t('wednesday'), t('thursday'), t('friday'), t('saturday')],
    dayNamesShort: [t('sun'), t('mon'), t('tue'), t('wed'), t('thu'), t('fri'), t('sat')],
    dayNamesMin: [t('sun'), t('mon'), t('tue'), t('wed'), t('thu'), t('fri'), t('sat')],
    monthNames: [t('jan'), t('feb'), t('mar'), t('apr'), t('may'), t('jun'), t('jul'), t('aug'), t('sep'),
      t('oct'), t('nov'), t('dec')],
    monthNamesShort: [t('jan'), t('feb'), t('mar'), t('apr'), t('may'), t('jun'), t('jul'), t('aug'), t('sep'),
      t('oct'), t('nov'), t('dec')],
    today: t('today'),
    clear: t('delete')
  });


  useEffect(() => {
    if (formData && formData.residential_unit && formData.residential_unit.id) {
      setFormData({
        ...formData,
        residential_unit: formData.residential_unit.id
      })
    }
  }, [formData.residential_unit])

  useEffect(() => {
    if (formData && formData.berth && formData.berth.id) {
      setFormData({
        ...formData,
        berth: formData.berth.id
      })
    }
  }, [formData.berth])

  useEffect(() => {
    axios({
      method: 'get',
      url: process.env.REACT_APP_API_PROTOCOL + process.env.REACT_APP_API_HOST + "residential-unit",
      params: {},
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem("userToken"),
        "Sys-name": localStorage.getItem('instanceId'),
        "Preferred-locale": localStorage.getItem('language')
      }
    })
      .then(response => {
        setUnit(response.data.data.items);
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
    axios({
      method: 'get',
      url: process.env.REACT_APP_API_PROTOCOL + process.env.REACT_APP_API_HOST + "berth",
      params: {},
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem("userToken"),
        "Sys-name": localStorage.getItem('instanceId'),
        "Preferred-locale": localStorage.getItem('language')
      }
    })
      .then(response => {
        setLoading(false)
        setBerth(response.data.data.items);
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
        setLoading(false)
      })
    if (window.App.state.propsReservation && window.App.state.propsReservation.id) {
      setLoading(true)
      axios({
        method: 'get',
        url: process.env.REACT_APP_API_PROTOCOL + process.env.REACT_APP_API_HOST + "reservation/" + window.App.state.propsReservation.id,
        params: {},
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem("userToken"),
          "Sys-name": localStorage.getItem('instanceId'),
          "Preferred-locale": localStorage.getItem('language')
        }
      })
        .then(response => {
          setLoading(false)
          if (response.data && response.data.data && response.data.data.residential_unit && response.data.data.residential_unit.id) {
            checkAllday(response.data.data.residential_unit.id)
          }
          setTimeout(() => {
            setFormData(response.data.data);
          },1000)
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
          setLoading(false)
        })
    }

  }, [])

  return (
    <Form onSubmit={onSubmit} initialValues={formData}
          validate={validate} render={({handleSubmit}) => (
      <form onSubmit={handleSubmit} className="">
        <Panel className={"p-mt-3"}>
          <div className={"p-grid"}>
            <div className={"p-col"}>
              <h2 className={"p-m-0"}>{t('reservation_form')}</h2>
            </div>
            <div className={"p-col p-text-right p-col-align-center"}>
              <Button type="submit" aria-label={t('save_and_close')}
                      loading={loading}
                      onClick={() => {
                        props = {...props, closeForm: true}
                      }}
                      className="p-button-success p-ml-2">
              <span>
                <i className={"pi pi-save"}></i>&nbsp;&nbsp;<i className={"pi pi-backward"}></i>
              </span>
              </Button>
              <Button type="submit" aria-label={t('save')} icon={"pi pi-save"}
                      loading={loading}
                      onClick={() => {
                        props = {...props, closeForm: false}
                      }}
                      className="p-button-success p-ml-2"/>
            </div>
          </div>
          <div className={"p-grid"}>
            <div className={"p-col-12 p-lg-6 p-xl-6"}>
              <Field name="residential_unit" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <label htmlFor="residential_unit"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>{t('residential_unit')}</label>
                  <span className="">
                      <Dropdown options={unit}
                                optionLabel="name"
                                optionValue="id"
                                filter
                                id={"residential_unit"}
                                emptyMessage={"Még nincs egység felvéve..."}
                                onChange={(e) => {
                                  setFormData({
                                    ...formData,
                                    residential_unit: e.value
                                  })
                                  checkAllday(e.value)
                                }}
                                value={formData.residential_unit}
                                showClear
                      />
                    </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
            <div className={"p-col-12 p-lg-6 p-xl-6"}>
              <Field name="berth" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <label htmlFor="berth"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>{t('berth')}</label>
                  <span className="">
                    {formData.residential_unit ? (
                      <Dropdown options={berth}
                                optionLabel="name"
                                optionValue="id"
                                filter
                                id={"berth"}
                                emptyMessage={"Még nincs ágy felvéve..."}
                                onChange={(e) => {
                                  setFormData({
                                    ...formData,
                                    berth: e.value
                                  })
                                }}
                                value={formData.berth}
                                showClear
                      />
                    ) : (
                      <Dropdown disabled/>
                    )}
                    </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
            <div className={"p-col-12 p-lg-6 p-xl-6"} style={{minWidth: '160px'}}>
              <Field name="reserved_from" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <label htmlFor="reserved_from"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>{t('reserved_from')}</label>
                  <span className="">
                            {formData.residential_unit ? (
                              <>
                                {allday === false ? (
                                  <Calendar value={formData.reserved_from}
                                            placeholder={moment.utc(formData.reserved_from).local().format('YYYY-MM-DD HH:mm')}
                                            locale="default"
                                            id={"reserved_from"}
                                            showIcon
                                            showTime
                                            hourFormat="24"
                                            onChange={(e) => {
                                              setFormData({
                                                ...formData,
                                                reserved_from: moment.utc(e.value).local().format('YYYY-MM-DD HH:mm:ss')
                                              })
                                            }}/>
                                ) : (
                                  <div className="p-inputgroup calendar-icon-only">
                                    <InputMask id={"reserved_from"}
                                               mask={"9999-99-99"}
                                               placeholder={t('ÉÉÉÉ-HH-NN')}
                                               value={formData.reserved_from}
                                               onChange={(e) => {
                                                 if (e.value.indexOf("_") === -1) {
                                                   if (e.value === '') {
                                                     e.value = null
                                                   }
                                                   setFormData({
                                                     ...formData,
                                                     reserved_from: e.value
                                                   })
                                                 }
                                               }}
                                    />
                                    <Calendar value={formData.reserved_from}
                                              locale="default"
                                              format={'yy-mm-dd'}
                                              showIcon
                                              onChange={(e) => {
                                                setFormData({
                                                  ...formData,
                                                  reserved_from: moment.utc(e.value).local().format('YYYY-MM-DD')
                                                })
                                              }}/>
                                  </div>
                                )}
                              </>
                            ) : (
                              <InputMask placeholder={t('ÉÉÉÉ-HH-NN')} mask={"9999-99-99"} disabled/>
                            )}
                        </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
            <div className={"p-col-12 p-lg-6 p-xl-6"} style={{minWidth: '160px'}}>
              <Field name="reserved_to" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <label htmlFor="reserved_from"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>{t('reserved_to')}</label>
                  <span className="">
                    {formData.residential_unit ? (
                      <>
                        {allday === false ? (
                          <Calendar value={formData.reserved_to}
                                    placeholder={moment.utc(formData.reserved_to).local().format('YYYY-MM-DD HH:mm')}
                                    locale="default"
                                    id={"reserved_to"}
                                    format={'yy-mm-dd'}
                                    showIcon
                                    showTime
                                    hourFormat="24"
                                    onChange={(e) => {
                                      setFormData({
                                        ...formData,
                                        reserved_to: moment.utc(e.value).local().format('YYYY-MM-DD HH:mm:ss')
                                      })
                                    }}/>
                        ) : (
                          <div className="p-inputgroup calendar-icon-only">
                            <InputMask id={"reserved_to"}
                                       mask={"9999-99-99"}
                                       placeholder={t('ÉÉÉÉ-HH-NN')}
                                       value={formData.reserved_to}
                                       onChange={(e) => {
                                         if (e.value.indexOf("_") === -1) {
                                           if (e.value === '') {
                                             e.value = null
                                           }
                                           setFormData({
                                             ...formData,
                                             reserved_to: e.value
                                           })
                                         }
                                       }}
                            />
                            <Calendar value={formData.reserved_to}
                                      locale="default"
                                      format={'yy-mm-dd'}
                                      showIcon
                                      onChange={(e) => {
                                        setFormData({
                                          ...formData,
                                          reserved_to: moment.utc(e.value).local().format('YYYY-MM-DD')
                                        })
                                      }}/>
                          </div>
                        )}
                      </>
                    ) : (
                      <InputMask placeholder={t('ÉÉÉÉ-HH-NN')} mask={"9999-99-99"} disabled/>
                    )}
                        </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
            <div className={"p-col-12 p-lg-6 p-xl-6"}>
              <Field name="note" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <label htmlFor="note"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>{t('note')}</label>
                  <span className="p-d-block">
                      <InputText id="note" {...input}
                                 onChange={(e) => {
                                   setFormData({
                                     ...formData,
                                     note: e.target.value
                                   })
                                 }}
                                 value={formData.note}
                                 className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                    </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
            <div className={"p-col-12 p-lg-6 p-xl-6"}>
              <Field name="status" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <label htmlFor="status"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>{t('status')}</label>
                  <span className="p-d-block">
                      <InputText id="status" {...input}
                                 onChange={(e) => {
                                   setFormData({
                                     ...formData,
                                     status: e.target.value
                                   })
                                 }}
                                 value={formData.status}
                                 className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                    </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
          </div>
        </Panel>
      </form>
    )}/>
  )
}
export default withTranslation('common')(_FM_ReservationFormComponent);
