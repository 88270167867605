import React, {useState, useEffect, useRef} from 'react';
import {withTranslation} from 'react-i18next';
import {Panel} from "primereact/panel";
import {Card} from "primereact/card";
import {Chart} from 'primereact/chart';

const ControlpanelPage = (props) => {

  const {t, i18n} = props;

  useEffect(() => {
    document.title = t('control_panel') + ' - ' + process.env.REACT_APP_TITLE;
  }, []);

  return (
    <div className="ControlpanelPage p-component p-m-lg-4">
      <div className={"p-p-3 p-p-lg-0"}>
        {process.env.REACT_APP_BRAND === 'felhomatrac' &&
        <>
          <div className={"p-grid kpi"}>
            <div className={"p-col-12 p-lg-2"}>
              <Card className={""}>
                <div className={"p-grid"}>
                  <div className={"p-col-9 p-col-align-center"}>
                    <small className={"p-text-uppercase"}>Foglalások</small>
                    <h3 className={"p-m-0 p-p-0 p-text-secondary"}> 2024. november</h3>
                  </div>
                  <div className={"p-col-3 p-col-align-center p-text-right"}>
                    <i className={"pi pi-chart-bar"} style={{'fontSize': '1.5em'}}></i>
                  </div>
                  <div className={"p-col-12"}>
                      <span className={"p-text-bold"} style={{color: 'green'}}>
                        <i className={"pi pi-arrow-up"}></i>
                        &nbsp;
                        40
                      </span>
                    &nbsp;
                    foglalt éjszaka
                  </div>
                </div>
              </Card>
            </div>
            <div className={"p-col-12 p-lg-2"}>
              <Card className={""}>
                <div className={"p-grid"}>
                  <div className={"p-col-9 p-col-align-center"}>
                    <small className={"p-text-uppercase"}>Bevételek</small>
                    <h3 className={"p-m-0 p-p-0 p-text-secondary"}> 2024. november</h3>
                  </div>
                  <div className={"p-col-3 p-col-align-center p-text-right"}>
                    <i className={"pi pi-chart-bar"} style={{'fontSize': '1.5em'}}></i>
                  </div>
                  <div className={"p-col-12"}>
                      <span className={"p-text-bold"} style={{color: 'green'}}>
                        <i className={"pi pi-arrow-up"}></i>
                        &nbsp;
                        2 111 420 HUF
                      </span>
                    &nbsp;
                    teljes bevétel
                  </div>
                </div>
              </Card>
            </div>
            <div className={"p-col-12 p-lg-2"}>
              <Card className={""}>
                <div className={"p-grid"}>
                  <div className={"p-col-9 p-col-align-center"}>
                    <small className={"p-text-uppercase"}>Kihasználtság</small>
                    <h3 className={"p-m-0 p-p-0 p-text-secondary"}> 2024. november</h3>
                  </div>
                  <div className={"p-col-3 p-col-align-center p-text-right"}>
                    <i className={"pi pi-chart-bar"} style={{'fontSize': '1.5em'}}></i>
                  </div>
                  <div className={"p-col-12"}>
                      <span className={"p-text-bold"} style={{color: 'red'}}>
                        <i className={"pi pi-arrow-down"}></i>
                        &nbsp;
                        44%
                      </span>
                    &nbsp;
                    foglalások aránya
                  </div>
                </div>
              </Card>
            </div>
            <div className={"p-col-12 p-lg-2"}>
              <Card className={""}>
                <div className={"p-grid"}>
                  <div className={"p-col-9 p-col-align-center"}>
                    <small className={"p-text-uppercase"}>Napi ár</small>
                    <h3 className={"p-m-0 p-p-0 p-text-secondary"}> 2024. november</h3>
                  </div>
                  <div className={"p-col-3 p-col-align-center p-text-right"}>
                    <i className={"pi pi-chart-bar"} style={{'fontSize': '1.5em'}}></i>
                  </div>
                  <div className={"p-col-12"}>
                      <span className={"p-text-bold"} style={{color: 'red'}}>
                        <i className={"pi pi-arrow-down"}></i>
                        &nbsp;
                        32 000 HUF
                      </span>
                    &nbsp;
                    átlagos ár
                  </div>
                </div>
              </Card>
            </div>
            <div className={"p-col-12 p-lg-4"}>
              <Card className={""}>
                <div className={"p-grid"}>
                  <div className={"p-col-9 p-col-align-center"}>
                    <small className={"p-text-uppercase"}>Csatornák</small>
                    <h3 className={"p-m-0 p-p-0 p-text-secondary"}> 2024. november</h3>
                  </div>
                  <div className={"p-col-3 p-col-align-center p-text-right"}>
                    <i className={"pi pi-chart-bar"} style={{'fontSize': '1.5em'}}></i>
                  </div>
                  <div className={"p-col-4"}>
                      <span className={"p-text-bold"} style={{color: 'red'}}>
                        <i className={"pi pi-arrow-down"}></i>
                        &nbsp;
                        23
                      </span>
                    &nbsp;
                    Airbnb
                  </div>
                  <div className={"p-col-4"}>
                      <span className={"p-text-bold"} style={{color: 'red'}}>
                        <i className={"pi pi-arrow-down"}></i>
                        &nbsp;
                        20
                      </span>
                    &nbsp;
                    Booking.com
                  </div>
                  <div className={"p-col-4"}>
                      <span className={"p-text-bold"} style={{color: 'green'}}>
                        <i className={"pi pi-arrow-up"}></i>
                        &nbsp;
                        80
                      </span>
                    &nbsp;
                    Szallas.hu
                  </div>
                </div>
              </Card>
            </div>
          </div>

          <div className={"p-grid p-mt-0 chart"}>
            <div className={"p-col-12 p-lg-4 p-col-align-center"}>
              <Panel>
                <small className={"p-text-uppercase"}>Csatonák</small>
                <h3 className={"p-m-0 p-p-0 p-text-secondary"}> 2024. november</h3>
                <Chart type="doughnut" data={
                  {
                    labels: ['Direkt foglalás', 'Airbnb', 'Booking.com', 'Szallas.hu'],
                    datasets: [
                      {
                        data: [2140, 540, 325, 702],
                      }
                    ]
                  }
                } options={
                  {
                    cutout: '30%',
                    plugins: {
                      legend: {
                        labels: {
                          usePointStyle: true
                        }
                      }
                    }
                  }
                } className="w-full"/>
              </Panel>
            </div>
            <div className={"p-col-12 p-lg-4 p-col-align-center"}>
              <Panel>
                <small className={"p-text-uppercase"}>Bevételi arány</small>
                <h3 className={"p-m-0 p-p-0 p-text-secondary"}> 2024. november</h3>
                <Chart type="doughnut" data={
                  {
                    labels: ['Foglalások', 'Szolgáltatások', 'Egyéb'],
                    datasets: [
                      {
                        data: [222140, 23540, 1325],
                      }
                    ]
                  }
                } options={
                  {
                    cutout: '30%',
                    plugins: {
                      legend: {
                        labels: {
                          usePointStyle: true
                        }
                      }
                    }
                  }
                } className="w-full"/>
              </Panel>
            </div>
            <div className={"p-col-12 p-lg-4 p-col-align-center"}>
              <Panel>
                <small className={"p-text-uppercase"}>Soba típusok</small>
                <h3 className={"p-m-0 p-p-0 p-text-secondary"}> 2024. november</h3>
                <Chart type="doughnut" data={
                  {
                    labels: ['Economy', 'Standard', 'Superior', 'Apartman', 'Suite'],
                    datasets: [
                      {
                        data: [4, 12, 1, 4, 5],
                      }
                    ]
                  }
                } options={
                  {
                    cutout: '30%',
                    plugins: {
                      legend: {
                        labels: {
                          usePointStyle: true
                        }
                      }
                    }
                  }
                } className="w-full"/>
              </Panel>
            </div>
          </div>
        </>
        }
      </div>
    </div>
  )
}
export default withTranslation('common')(ControlpanelPage);
