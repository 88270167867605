import React, {useState, useEffect, useRef} from 'react';
import {withTranslation} from 'react-i18next';
import {Panel} from "primereact/panel";
import {Tree} from 'primereact/tree';
import axios from "axios";
import {Dropdown} from "primereact/dropdown";
import {Button} from "primereact/button";
import _FM_RescategoryFormComponent from "../components/_FM_rescategory/_FM_RescategoryFormComponent";
import {Sidebar} from "primereact/sidebar";
import _FM_ResunitFormComponent from "../components/_FM_resunit/_FM_ResunitFormComponent";
import _FM_BerthFormComponent from "../components/_FM_berth/_FM_BerthFormComponent";
import {InputText} from "primereact/inputtext";
import {InputNumber} from "primereact/inputnumber";
import {OverlayPanel} from "primereact/overlaypanel";
import _FM_ResunitFormBulkComponent from "../components/_FM_resunit/_FM_ResunitFormBulkComponent";
import _FM_BerthFormBulkComponent from "../components/_FM_berth/_FM_BerthFormBulkComponent";

const _FM_BuilderPage = (props) => {

  const {t, i18n} = props;
  const [accommodations, setAccommodations] = useState([])
  const [ntakKategoria, setNtakKategoria] = useState([])
  const [nodes, setNodes] = useState([])
  const [selectedKeys, setSelectedKeys] = useState(null);
  const [selectedIds, setSelectedIds] = useState([]);
  const [categories, setCategories] = useState([]);
  const [units, setUnits] = useState([]);
  const [beds, setBeds] = useState([]);
  const opb = useRef(null);
  const opu = useRef(null);

  useEffect(() => {
    document.title = t('builder') + ' - ' + process.env.REACT_APP_TITLE;
    axios({
      method: 'get',
      url: process.env.REACT_APP_API_PROTOCOL + process.env.REACT_APP_API_HOST + "accommodation",
      params: {},
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem("userToken"),
        "Sys-name": localStorage.getItem('instanceId'),
        "Preferred-locale": localStorage.getItem('language')
      }
    })
      .then(response => {
        setAccommodations(response.data.data.items);
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
    axios({
      method: 'get',
      url: process.env.REACT_APP_API_PROTOCOL + process.env.REACT_APP_API_HOST + "dictionary/ntak/kategoria",
      params: {},
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem("userToken"),
        "Sys-name": localStorage.getItem('instanceId'),
        "Preferred-locale": localStorage.getItem('language')
      }
    })
      .then(response => {
        setNtakKategoria(response.data.data);
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
  }, []);

  useEffect(() => {
    if (window.App.state.selectedAccommodation) {
      axios({
        method: 'get',
        url: process.env.REACT_APP_API_PROTOCOL + process.env.REACT_APP_API_HOST + "residential-category?search[accommodation]="
          + window.App.state.selectedAccommodation,
        params: {},
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem("userToken"),
          "Sys-name": localStorage.getItem('instanceId'),
          "Preferred-locale": localStorage.getItem('language')
        }
      })
        .then(response => {
          let _cats = []
          response.data.data.items.map((cat, index) => {
            _cats.push({
              key: 'C-' + cat.id,
              originalId: cat.id,
              originalData: cat,
              parentId: null,
              type: 'category',
              level: 1,
              label: cat.name,
              expanded: true,
              draggable: false,
              selectable: false,
            })
          })
          setCategories(_cats)
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
        })
      axios({
        method: 'get',
        url: process.env.REACT_APP_API_PROTOCOL + process.env.REACT_APP_API_HOST + "residential-unit",
        params: {},
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem("userToken"),
          "Sys-name": localStorage.getItem('instanceId'),
          "Preferred-locale": localStorage.getItem('language')
        }
      })
        .then(response => {
          let _units = []
          response.data.data.items.map((unit, index) => {
            _units.push({
              key: 'U-' + unit.id,
              originalId: unit.id,
              originalData: unit,
              parentId: unit.residential_category.id,
              type: 'unit',
              level: 2,
              label: unit.name + " - " + unit.id,
              expanded: true,
              draggable: true,
              selectable: false,
            })
          })
          setUnits(_units)
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
        })
      axios({
        method: 'get',
        url: process.env.REACT_APP_API_PROTOCOL + process.env.REACT_APP_API_HOST + "berth",
        params: {},
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem("userToken"),
          "Sys-name": localStorage.getItem('instanceId'),
          "Preferred-locale": localStorage.getItem('language')
        }
      })
        .then(response => {
          let _beds = []
          response.data.data.items.map((bed, index) => {
            _beds.push({
              key: 'B-' + bed.id,
              originalId: bed.id,
              originalData: bed,
              parentId: bed.residential_unit.id,
              type: 'bed',
              level: 3,
              label: bed.name,
              draggable: true,
            })
          })
          setBeds(_beds)
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
        })
    }
    window.App.setState({
      rerenderRescategoryList: false,
      rerenderResunitList: false,
      rerenderBerthList: false,
    })
  }, [
    window.App.state.selectedAccommodation,
    window.App.state.rerenderRescategoryList === true,
    window.App.state.rerenderResunitList === true,
    window.App.state.rerenderBerthList === true,
  ]);

  useEffect(() => {
    if (window.App.state.selectedAccommodation) {
      axios({
        method: 'get',
        url: process.env.REACT_APP_API_PROTOCOL + process.env.REACT_APP_API_HOST + "residential-category?search[accommodation]="
          + window.App.state.selectedAccommodation,
        params: {},
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem("userToken"),
          "Sys-name": localStorage.getItem('instanceId'),
          "Preferred-locale": localStorage.getItem('language')
        }
      })
        .then(response => {
          let _cats = []
          response.data.data.items.map((cat, index) => {
            _cats.push({
              key: 'C-' + cat.id,
              originalId: cat.id,
              originalData: cat,
              parentId: null,
              type: 'category',
              level: 1,
              label: cat.name,
              expanded: true,
              draggable: false,
              selectable: false,
            })
          })
          setCategories(_cats)
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
        })
      axios({
        method: 'get',
        url: process.env.REACT_APP_API_PROTOCOL + process.env.REACT_APP_API_HOST + "residential-unit/tree",
        params: {},
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem("userToken"),
          "Sys-name": localStorage.getItem('instanceId'),
          "Preferred-locale": localStorage.getItem('language')
        }
      })
        .then(response => {
          let _units = []
          response.data.map((unit, index) => {
            _units.push({
              key: 'U-' + unit.id,
              originalId: unit.id,
              originalData: unit,
              parentId: unit.residential_category.id,
              type: 'unit',
              level: 2,
              label: unit.name,
              expanded: true,
              draggable: true,
              selectable: false,
              children: unit.children
            })
          })
          setUnits(_units)
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
        })
      axios({
        method: 'get',
        url: process.env.REACT_APP_API_PROTOCOL + process.env.REACT_APP_API_HOST + "berth",
        params: {},
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem("userToken"),
          "Sys-name": localStorage.getItem('instanceId'),
          "Preferred-locale": localStorage.getItem('language')
        }
      })
        .then(response => {
          let _beds = []
          response.data.data.items.map((bed, index) => {
            _beds.push({
              key: 'B-' + bed.id,
              originalId: bed.id,
              originalData: bed,
              parentId: bed.residential_unit.id,
              type: 'bed',
              level: 3,
              label: bed.name,
              draggable: true,
            })
          })
          setBeds(_beds)
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
        })
    }
    window.App.setState({
      rerenderRescategoryList: false,
      rerenderResunitList: false,
      rerenderBerthList: false,
    })
  }, [
    window.App.state.selectedAccommodation,
    window.App.state.rerenderRescategoryList === true,
    window.App.state.rerenderResunitList === true,
    window.App.state.rerenderBerthList === true,
  ]);

  useEffect(() => {
    let _nodes = []
    categories.map((category, index) => {
      let _units = units.filter(item => item.parentId === category.originalId)
      let __units = []
      _units.map((unit, index) => {
        let _beds = beds.filter(item => item.parentId === unit.originalId)
        let _children = []
        if (unit.children && unit.children[0]) {
          unit.children.map((children, index) => {
            let __beds = []
            if (children.berths && children.berths[0]) {
              children.berths.map((bed, index) => {
                __beds.push({
                  key: 'BB-' + bed.id,
                  originalId: bed.id,
                  originalData: bed,
                  parentId: children.originalId,
                  type: 'bed',
                  level: 4,
                  label: bed.name,
                  draggable: true,
                })
              })
            }
            _children.push({
              key: 'UU-' + children.id,
              originalId: children.id,
              originalData: children,
              parentId: unit.originalId,
              type: 'unit',
              level: 3,
              label: children.name,
              expanded: true,
              draggable: true,
              selectable: false,
              children: __beds
            })
          })
          _beds = [
            ..._beds,
            ..._children
          ]
        }
        __units.push({
          ...unit,
          children: _beds
        })
      })
      _nodes.push({
        ...category,
        children: __units
      })
    })
    setNodes(_nodes)
  }, [categories, units, beds]);

  return (
    <div className="_FM_BuilderPage p-component p-m-lg-4">
      <Panel>
        <div className={"p-grid"}>
          <div className={"p-col-12 p-lg-6 p-col-align-center"}>
            {window.App.state.selectedAccommodation && window.App.state.selectedAccommodation !== null &&
            <Button type="button" label={t('new_rescategory')} icon={"pi pi-plus"}
                    onClick={() => {
                      window.App.setState({
                        newAccommodationId: window.App.state.selectedAccommodation,
                        propsRescategory: null,
                        sidebarRescategoryForm: true
                      })
                    }}
                    className="p-button-success p-mr-2"/>
            }
            {selectedIds && selectedIds[0] &&
            <Button type="button" label={t('delete_selected')} icon={"pi pi-trash"}
                    onClick={() => {
                      if (window.confirm('A törlés megerősítésére van szükség!')) {
                        axios({
                          method: 'delete',
                          url: process.env.REACT_APP_API_PROTOCOL + process.env.REACT_APP_API_HOST + "berth/bulk-delete",
                          params: {},
                          headers: {
                            'Authorization': 'Bearer ' + localStorage.getItem("userToken"),
                            "Sys-name": localStorage.getItem('instanceId'),
                            "Preferred-locale": localStorage.getItem('language')
                          },
                          data: selectedIds
                        })
                          .then(response => {
                            window.App.toastShow('success', 'Sikeres törlés!',);
                            window.App.setState({
                              rerenderBerthList: true,
                            })
                          })
                          .catch(error => {
                            window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
                          })
                      }
                    }}
                    className="p-button-danger p-mr-2"/>
            }
          </div>
          <div className={"p-col-12 p-lg-6 p-col-align-center p-text-right"}>
            {nodes && !nodes[0] &&
            <>Válassz száláshelyet: &nbsp;</>
            }
            <Dropdown options={accommodations}
                      optionLabel="name"
                      optionValue="id"
                      filter
                      id={"accommodation"}
                      emptyMessage={"Még nincs szálláshely felvéve..."}
                      onChange={(e) => {
                        window.App.setState({selectedAccommodation: e.value})
                      }}
                      value={window.App.state.selectedAccommodation}
            />
          </div>
        </div>
        {nodes && nodes[0] &&
        <>
          <Tree value={nodes}
                header={() => {
                  return (
                    <>
                      <div className={"category"}>
                        <div className={"p-grid"}>
                          <div className={"p-col-2"}>

                          </div>
                          <div className={"p-col-2 p-text-center"}>
                            {t('name')}
                          </div>
                          <div className={"p-col-2 p-text-center"}>
                            {t('ntak_kategoria')}
                          </div>
                          <div className={"p-col-2 p-text-center"}>
                            {t('number_of_double_beds')}
                          </div>
                          <div className={"p-col-2 p-text-center"}>
                            {t('number_of_single_beds')}
                          </div>
                          <div className={"p-col-2"}>

                          </div>
                        </div>
                      </div>
                      <div className={"unit"}>
                        <div className={"p-grid"}>
                          <div className={"p-col-2"}>

                          </div>
                          <div className={"p-col-2 p-text-center"}>
                            {t('name')}
                          </div>
                          <div className={"p-col-2 p-text-center"}>
                            {t('number_of_extra_beds')}
                          </div>
                          <div className={"p-col-2 p-text-center"}>
                            {t('number_of_double_beds')}
                          </div>
                          <div className={"p-col-2 p-text-center"}>
                            {t('number_of_single_beds')}
                          </div>
                          <div className={"p-col-2"}>

                          </div>
                        </div>
                      </div>
                    </>
                  )
                }}
                metaKeySelection={true}
                selectionMode="multiple"
                selectionKeys={selectedKeys}
                onSelectionChange={(e, node) => {
                  let _selectedIds = []
                  let values = Object.keys(e.value)
                  if (values && values[0]) {
                    values.map((value, index) => {
                      var selectedId = beds.filter(item => item.key === value)
                      _selectedIds.push(selectedId[0].originalId)
                    })
                  }
                  setSelectedIds(_selectedIds)
                  setSelectedKeys(e.value)
                }}
                dragdropScope="0"
                onDragDrop={(e) => {
                  if(e.dragNode.type && e.dragNode.type === 'unit') {
                    if(e.dropNode.type && e.dropNode.type === 'category') {
                      axios.patch(process.env.REACT_APP_API_PROTOCOL + process.env.REACT_APP_API_HOST + "residential-unit/" + e.dragNode.originalId,
                        {
                          residential_category: e.dropNode.originalId,
                          parent: null
                        },
                        {
                          params: {},
                          headers: {
                            'Authorization': 'Bearer ' + localStorage.getItem("userToken"),
                            "Sys-name": localStorage.getItem('instanceId'),
                            "Preferred-locale": localStorage.getItem('language')
                          }
                        })
                        .then(response => {
                          window.App.toastShow('success', t('success'), response.data.message)
                          window.App.setState({
                            rerenderResunitList: true,
                          })
                        })
                        .catch(error => {
                          window.App.toastShow('error', t('error'), error.response.data.message, '', error.response)
                        })
                    }
                    else if(e.dropNode.type && e.dropNode.type === 'unit') {
                      axios.patch(process.env.REACT_APP_API_PROTOCOL + process.env.REACT_APP_API_HOST + "residential-unit/" + e.dragNode.originalId,
                        {
                          parent: e.dropNode.originalId
                        },
                        {
                          params: {},
                          headers: {
                            'Authorization': 'Bearer ' + localStorage.getItem("userToken"),
                            "Sys-name": localStorage.getItem('instanceId'),
                            "Preferred-locale": localStorage.getItem('language')
                          }
                        })
                        .then(response => {
                          window.App.toastShow('success', t('success'), response.data.message)
                          window.App.setState({
                            rerenderResunitList: true,
                          })
                        })
                        .catch(error => {
                          window.App.toastShow('error', t('error'), error.response.data.message, '', error.response)
                        })
                    }
                  }
                  else if(e.dragNode.type && e.dragNode.type === 'bed') {
                    axios.patch(process.env.REACT_APP_API_PROTOCOL + process.env.REACT_APP_API_HOST + "berth/" + e.dragNode.originalId,
                      {
                        residential_unit: e.dropNode.originalId
                      },
                      {
                        params: {},
                        headers: {
                          'Authorization': 'Bearer ' + localStorage.getItem("userToken"),
                          "Sys-name": localStorage.getItem('instanceId'),
                          "Preferred-locale": localStorage.getItem('language')
                        }
                      })
                      .then(response => {
                        window.App.toastShow('success', t('success'), response.data.message)
                        window.App.setState({
                          rerenderBerthList: true,
                        })
                      })
                      .catch(error => {
                        window.App.toastShow('error', t('error'), error.response.data.message, '', error.response)
                      })
                  }
                }}
                togglerTemplate={() => {
                  return (
                    false
                  )
                }}
                nodeTemplate={(node, options) => {
                  if (node.type === 'category') {
                    return (
                      <div className={node.type}>
                        <div className={"p-grid"}>
                          <div className={"p-col-2 p-col-align-center"}>
                            <i className={"pi pi-star p-mr-2"}></i>
                            {t('residential_category')}
                          </div>
                          <div className={"p-col-2 p-col-align-center"}>
                            <InputText id={node.originalId + "-name"}
                                       onBlur={(e) => {
                                         if (e.target.value) {
                                           axios.patch(process.env.REACT_APP_API_PROTOCOL + process.env.REACT_APP_API_HOST + "residential-category/" + node.originalId,
                                             {
                                               name: e.target.value
                                             },
                                             {
                                               params: {},
                                               headers: {
                                                 'Authorization': 'Bearer ' + localStorage.getItem("userToken"),
                                                 "Sys-name": localStorage.getItem('instanceId'),
                                                 "Preferred-locale": localStorage.getItem('language')
                                               }
                                             })
                                             .then(response => {
                                               window.App.toastShow('success', t('success'), response.data.message)
                                               window.App.setState({
                                                 rerenderResunitList: true,
                                               })
                                             })
                                             .catch(error => {
                                               window.App.toastShow('error', t('error'), error.response.data.message, '', error.response)
                                             })
                                         }
                                       }}
                                       placeholder={node.label}
                                       className={"w-100"}/>
                          </div>
                          <div className={"p-col-2 p-col-align-center"}>
                            <Dropdown options={ntakKategoria}
                                      optionLabel="key"
                                      optionValue="value"
                                      filter
                                      id={node.originalId + "-ntak_kategoria"}
                                      emptyMessage={"Még nincs kategória felvéve..."}
                                      onChange={(e) => {
                                        axios.patch(process.env.REACT_APP_API_PROTOCOL + process.env.REACT_APP_API_HOST + "residential-category/" + node.originalId,
                                          {
                                            ntak_kategoria: e.value
                                          },
                                          {
                                            params: {},
                                            headers: {
                                              'Authorization': 'Bearer ' + localStorage.getItem("userToken"),
                                              "Sys-name": localStorage.getItem('instanceId'),
                                              "Preferred-locale": localStorage.getItem('language')
                                            }
                                          })
                                          .then(response => {
                                            window.App.toastShow('success', t('success'), response.data.message)
                                            window.App.setState({
                                              rerenderResunitList: true,
                                            })
                                          })
                                          .catch(error => {
                                            window.App.toastShow('error', t('error'), error.response.data.message, '', error.response)
                                          })
                                      }}
                                      value={node.originalData.ntak_kategoria}
                                      className={"w-100"}
                            />
                          </div>
                          <div className={"p-col-2 p-col-align-center"}>
                            <InputNumber id={node.originalId + "-number_of_double_beds"}
                                         locale="hu-HU"
                                         onBlur={(e) => {
                                           if (e.target.value) {
                                             axios.patch(process.env.REACT_APP_API_PROTOCOL + process.env.REACT_APP_API_HOST + "residential-category/" + node.originalId,
                                               {
                                                 number_of_double_beds: e.target.value
                                               },
                                               {
                                                 params: {},
                                                 headers: {
                                                   'Authorization': 'Bearer ' + localStorage.getItem("userToken"),
                                                   "Sys-name": localStorage.getItem('instanceId'),
                                                   "Preferred-locale": localStorage.getItem('language')
                                                 }
                                               })
                                               .then(response => {
                                                 window.App.toastShow('success', t('success'), response.data.message)
                                                 window.App.setState({
                                                   rerenderResunitList: true,
                                                 })
                                               })
                                               .catch(error => {
                                                 window.App.toastShow('error', t('error'), error.response.data.message, '', error.response)
                                               })
                                           }
                                         }}
                                         placeholder={node.originalData.number_of_double_beds}
                                         className={"w-100"}/>
                          </div>
                          <div className={"p-col-2 p-col-align-center"}>
                            <InputNumber id={node.originalId + "-number_of_single_beds"}
                                         locale="hu-HU"
                                         onBlur={(e) => {
                                           if (e.target.value) {
                                             axios.patch(process.env.REACT_APP_API_PROTOCOL + process.env.REACT_APP_API_HOST + "residential-category/" + node.originalId,
                                               {
                                                 number_of_single_beds: e.target.value
                                               },
                                               {
                                                 params: {},
                                                 headers: {
                                                   'Authorization': 'Bearer ' + localStorage.getItem("userToken"),
                                                   "Sys-name": localStorage.getItem('instanceId'),
                                                   "Preferred-locale": localStorage.getItem('language')
                                                 }
                                               })
                                               .then(response => {
                                                 window.App.toastShow('success', t('success'), response.data.message)
                                                 window.App.setState({
                                                   rerenderResunitList: true,
                                                 })
                                               })
                                               .catch(error => {
                                                 window.App.toastShow('error', t('error'), error.response.data.message, '', error.response)
                                               })
                                           }
                                         }}
                                         placeholder={node.originalData.number_of_single_beds}
                                         className={"w-100"}/>
                          </div>
                          <div className={"p-col-2 p-col-align-center p-text-right"}>
                            <Button type="button" icon="pi pi-bolt" tooltip={t('bulk_actions')}
                                    onClick={(e) => {
                                      window.App.setState({
                                        newCategoryId: node.originalId,
                                      })
                                      opu.current.toggle(e)
                                    }} small
                                    className="p-button-success p-ml-2"/>
                            <OverlayPanel ref={opu}>
                              <_FM_ResunitFormBulkComponent/>
                            </OverlayPanel>
                            <Button type="button" icon={"pi pi-plus"}
                                    onClick={() => {
                                      window.App.setState({
                                        newCategoryId: node.originalId,
                                        propsResunit: null,
                                        sidebarResunitForm: true
                                      })
                                    }}
                                    tooltip={t('new_resunit')}
                                    small
                                    className="p-button-success p-ml-2"/>
                            <Button icon="pi pi-trash" className="p-button-danger p-ml-2"
                                    tooltip="Törlés"
                                    small
                                    onClick={() => {
                                      if (window.confirm('A törlés megerősítésére van szükség!')) {
                                        axios({
                                          method: 'delete',
                                          url: process.env.REACT_APP_API_PROTOCOL + process.env.REACT_APP_API_HOST + "residential-category/" + node.originalId,
                                          params: {},
                                          headers: {
                                            'Authorization': 'Bearer ' + localStorage.getItem("userToken"),
                                            "Sys-name": localStorage.getItem('instanceId'),
                                            "Preferred-locale": localStorage.getItem('language')
                                          }
                                        })
                                          .then(response => {
                                            window.App.setState({rerenderRescategoryList: true})
                                            window.App.toastShow('success', 'Sikeres törlés!',);
                                          })
                                          .catch(error => {
                                            window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
                                          })
                                      }
                                    }}
                            />
                          </div>
                        </div>
                      </div>
                    )
                  }
                  if (node.type === 'unit') {
                    return (
                      <div className={node.type + " unit-level-" + node.level}>
                        <div className={"p-grid"}>
                          <div className={"p-col-2 p-col-align-center"}>
                            <i className={"pi pi-home p-mr-2 p-ml-5"}></i>
                            {t('residential_unit')}
                          </div>
                          <div className={"p-col-2 p-col-align-center"}>
                            <InputText id={node.originalId + "-name"}
                                       onBlur={(e) => {
                                         if (e.target.value) {
                                           axios.patch(process.env.REACT_APP_API_PROTOCOL + process.env.REACT_APP_API_HOST + "residential-unit/" + node.originalId,
                                             {
                                               name: e.target.value
                                             },
                                             {
                                               params: {},
                                               headers: {
                                                 'Authorization': 'Bearer ' + localStorage.getItem("userToken"),
                                                 "Sys-name": localStorage.getItem('instanceId'),
                                                 "Preferred-locale": localStorage.getItem('language')
                                               }
                                             })
                                             .then(response => {
                                               window.App.toastShow('success', t('success'), response.data.message)
                                               window.App.setState({
                                                 rerenderResunitList: true,
                                               })
                                             })
                                             .catch(error => {
                                               window.App.toastShow('error', t('error'), error.response.data.message, '', error.response)
                                             })
                                         }
                                       }}
                                       placeholder={node.label}
                                       className={"w-100"}/>
                          </div>
                          <div className={"p-col-2 p-col-align-center"}>
                            <InputNumber id={node.originalId + "-number_of_extra_beds"}
                                         locale="hu-HU"
                                         onBlur={(e) => {
                                           if (e.target.value) {
                                             axios.patch(process.env.REACT_APP_API_PROTOCOL + process.env.REACT_APP_API_HOST + "residential-unit/" + node.originalId,
                                               {
                                                 number_of_extra_beds: e.target.value
                                               },
                                               {
                                                 params: {},
                                                 headers: {
                                                   'Authorization': 'Bearer ' + localStorage.getItem("userToken"),
                                                   "Sys-name": localStorage.getItem('instanceId'),
                                                   "Preferred-locale": localStorage.getItem('language')
                                                 }
                                               })
                                               .then(response => {
                                                 window.App.toastShow('success', t('success'), response.data.message)
                                                 window.App.setState({
                                                   rerenderResunitList: true,
                                                 })
                                               })
                                               .catch(error => {
                                                 window.App.toastShow('error', t('error'), error.response.data.message, '', error.response)
                                               })
                                           }
                                         }}
                                         placeholder={node.originalData.number_of_extra_beds}
                                         className={"w-100"}/>
                          </div>
                          <div className={"p-col-2 p-col-align-center"}>
                            <InputNumber id={node.originalId + "-number_of_double_beds"}
                                         locale="hu-HU"
                                         onBlur={(e) => {
                                           if (e.target.value) {
                                             axios.patch(process.env.REACT_APP_API_PROTOCOL + process.env.REACT_APP_API_HOST + "residential-unit/" + node.originalId,
                                               {
                                                 number_of_double_beds: e.target.value
                                               },
                                               {
                                                 params: {},
                                                 headers: {
                                                   'Authorization': 'Bearer ' + localStorage.getItem("userToken"),
                                                   "Sys-name": localStorage.getItem('instanceId'),
                                                   "Preferred-locale": localStorage.getItem('language')
                                                 }
                                               })
                                               .then(response => {
                                                 window.App.toastShow('success', t('success'), response.data.message)
                                                 window.App.setState({
                                                   rerenderResunitList: true,
                                                 })
                                               })
                                               .catch(error => {
                                                 window.App.toastShow('error', t('error'), error.response.data.message, '', error.response)
                                               })
                                           }
                                         }}
                                         placeholder={node.originalData.number_of_double_beds}
                                         className={"w-100"}/>
                          </div>
                          <div className={"p-col-2 p-col-align-center"}>
                            <InputNumber id={node.originalId + "-number_of_single_beds"}
                                         locale="hu-HU"
                                         onBlur={(e) => {
                                           if (e.target.value) {
                                             axios.patch(process.env.REACT_APP_API_PROTOCOL + process.env.REACT_APP_API_HOST + "residential-unit/" + node.originalId,
                                               {
                                                 number_of_single_beds: e.target.value
                                               },
                                               {
                                                 params: {},
                                                 headers: {
                                                   'Authorization': 'Bearer ' + localStorage.getItem("userToken"),
                                                   "Sys-name": localStorage.getItem('instanceId'),
                                                   "Preferred-locale": localStorage.getItem('language')
                                                 }
                                               })
                                               .then(response => {
                                                 window.App.toastShow('success', t('success'), response.data.message)
                                                 window.App.setState({
                                                   rerenderResunitList: true,
                                                 })
                                               })
                                               .catch(error => {
                                                 window.App.toastShow('error', t('error'), error.response.data.message, '', error.response)
                                               })
                                           }
                                         }}
                                         placeholder={node.originalData.number_of_single_beds}
                                         className={"w-100"}/>
                          </div>
                          <div className={"p-col-2 p-col-align-center p-text-right"}>
                            <Button type="button" icon="pi pi-bolt" tooltip={t('bulk_actions')}
                                    onClick={(e) => {
                                      window.App.setState({
                                        newUnitId: node.originalId,
                                      })
                                      opb.current.toggle(e)
                                    }} small
                                    className="p-button-success p-ml-2"/>
                            <OverlayPanel ref={opb}>
                              <_FM_BerthFormBulkComponent/>
                            </OverlayPanel>
                            <Button type="button" icon={"pi pi-plus"}
                                    tooltip={t('new_berth')}
                                    onClick={() => {
                                      window.App.setState({
                                        newUnitId: node.originalId,
                                        propsBerth: null,
                                        sidebarBerthForm: true
                                      })
                                    }}
                                    small
                                    className="p-button-success p-ml-2"/>
                            <Button icon="pi pi-trash" className="p-button-danger p-ml-2"
                                    tooltip="Törlés"
                                    small
                                    onClick={() => {
                                      if (window.confirm('A törlés megerősítésére van szükség!')) {
                                        axios({
                                          method: 'delete',
                                          url: process.env.REACT_APP_API_PROTOCOL + process.env.REACT_APP_API_HOST + "residential-unit/" + node.originalId,
                                          params: {},
                                          headers: {
                                            'Authorization': 'Bearer ' + localStorage.getItem("userToken"),
                                            "Sys-name": localStorage.getItem('instanceId'),
                                            "Preferred-locale": localStorage.getItem('language')
                                          }
                                        })
                                          .then(response => {
                                            window.App.setState({rerenderResunitList: true})
                                            window.App.toastShow('success', 'Sikeres törlés!',);
                                          })
                                          .catch(error => {
                                            window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
                                          })
                                      }
                                    }}
                            />
                          </div>
                        </div>
                      </div>
                    )
                  }
                  if (node.type === 'bed') {
                    return (
                      <div className={node.type + " bed-level-" + node.level}>
                        <div className={"p-grid"}>
                          <div className={"p-col-2 p-col-align-center"}>
                            <span className={" p-ml-5"}></span><i className={"pi pi-inbox p-mr-2 p-ml-5"}></i>
                            {t('berth')}
                          </div>
                          <div className={"p-col-2 p-col-align-center"}>
                            <InputText id={node.originalId + "-name"}
                                       onBlur={(e) => {
                                         if (e.target.value) {
                                           axios.patch(process.env.REACT_APP_API_PROTOCOL + process.env.REACT_APP_API_HOST + "berth/" + node.originalId,
                                             {
                                               name: e.target.value
                                             },
                                             {
                                               params: {},
                                               headers: {
                                                 'Authorization': 'Bearer ' + localStorage.getItem("userToken"),
                                                 "Sys-name": localStorage.getItem('instanceId'),
                                                 "Preferred-locale": localStorage.getItem('language')
                                               }
                                             })
                                             .then(response => {
                                               window.App.toastShow('success', t('success'), response.data.message)
                                               window.App.setState({
                                                 rerenderResunitList: true,
                                               })
                                             })
                                             .catch(error => {
                                               window.App.toastShow('error', t('error'), error.response.data.message, '', error.response)
                                             })
                                         }
                                       }}
                                       placeholder={node.label}
                                       className={"w-100"}/>
                          </div>
                          <div className={"p-col-6"}>

                          </div>
                          <div className={"p-col-2 p-col-align-center p-text-right"}>
                            <Button icon="pi pi-trash" className="p-button-danger p-ml-2"
                                    tooltip="Törlés"
                                    small
                                    onClick={() => {
                                      if (window.confirm('A törlés megerősítésére van szükség!')) {
                                        axios({
                                          method: 'delete',
                                          url: process.env.REACT_APP_API_PROTOCOL + process.env.REACT_APP_API_HOST + "berth/" + node.originalId,
                                          params: {},
                                          headers: {
                                            'Authorization': 'Bearer ' + localStorage.getItem("userToken"),
                                            "Sys-name": localStorage.getItem('instanceId'),
                                            "Preferred-locale": localStorage.getItem('language')
                                          }
                                        })
                                          .then(response => {
                                            window.App.setState({rerenderBerthList: true})
                                            window.App.toastShow('success', 'Sikeres törlés!',);
                                          })
                                          .catch(error => {
                                            window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
                                          })
                                      }
                                    }}
                            />
                          </div>
                        </div>
                      </div>
                    )
                  }
                }}
          />
        </>
        }
      </Panel>
      <Sidebar position="right" visible={window.App.state.sidebarRescategoryForm} closeOnEscape={true}
               style={{width: '780px'}}
               onHide={() => window.App.setState({
                 sidebarRescategoryForm: false,
               })}>
        <_FM_RescategoryFormComponent/>
      </Sidebar>
      <Sidebar position="right" visible={window.App.state.sidebarResunitForm} closeOnEscape={true}
               style={{width: '780px'}}
               onHide={() => window.App.setState({
                 sidebarResunitForm: false,
               })}>
        <_FM_ResunitFormComponent/>
      </Sidebar>
      <Sidebar position="right" visible={window.App.state.sidebarBerthForm} closeOnEscape={true}
               style={{width: '780px'}}
               onHide={() => window.App.setState({
                 sidebarBerthForm: false,
               })}>
        <_FM_BerthFormComponent/>
      </Sidebar>
    </div>
  )
}
export default withTranslation('common')(_FM_BuilderPage);
