import React, {useState, useEffect, useRef} from 'react';
import {withTranslation} from 'react-i18next';
import {Panel} from "primereact/panel";
import {Sidebar} from "primereact/sidebar";

import PartnerListComponent from "../components/partner/PartnerListComponent";
import PartnerFormComponent from "../components/partner/PartnerFormComponent";

const PartnerPage = (props) => {

  const {t, i18n} = props;

  useEffect(() => {
    document.title = t('operator-data') + ' - ' + process.env.REACT_APP_TITLE;
  }, []);

  return (
    <div className="PartnerPage p-component p-m-lg-4">
      <Panel>
        <PartnerListComponent />
      </Panel>
      <Sidebar position="right" visible={window.App.state.sidebarPartnerForm} closeOnEscape={true}
               style={{width: '780px'}}
               onHide={() => window.App.setState({
                 sidebarPartnerForm: false,
               })}>
        <PartnerFormComponent />
      </Sidebar>
      <Sidebar position="right" visible={window.App.state.sidebarPartnerForm} closeOnEscape={true}
               style={{width: '780px'}}
               onHide={() => window.App.setState({
                 sidebarPartnerForm: false,
               })}>
        <PartnerFormComponent />
      </Sidebar>
    </div>
  )
}
export default withTranslation('common')(PartnerPage);
