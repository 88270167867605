import React, {Component, useRef} from 'react';
import {HashRouter as Router, Routes, Route, Link} from "react-router-dom";
import {withTranslation} from 'react-i18next';
import {Toast} from 'primereact/toast';
import Favicon from 'react-favicon';
import {BlockUI} from "primereact/blockui";
import i18next from 'i18next';

import 'primereact/resources/primereact.min.css';
import 'primereact/resources/themes/bootstrap4-light-purple/theme.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.scss';
import './assets/css/app.css';

import NavbarComponent from "./components/NavbarComponent";

import LoginPage from "./pages/LoginPage";
import RegistrationPage from "./pages/RegisterPage";
import PasswordresetRequestPage from "./pages/PasswordresetRequestPage";
import PasswordresetSetPage from "./pages/PasswordresetSetPage";
import ControlpanelPage from "./pages/ControlpanelPage";
import UsersPage from "./pages/UsersPage";
import ProfilePage from "./pages/ProfilePage";
import PartnerPage from "./pages/PartnerPage";

import _FM_CalendarPage from "./pages/_FM_CalendarPage";
import _FM_OperatorPage from "./pages/_FM_OperatorPage";
import _FM_AccommodationPage from "./pages/_FM_AccommodationPage";
import _FM_BuilingPage from "./pages/_FM_BuilingPage";
import _FM_RescategoryPage from "./pages/_FM_RescategoryPage";
import _FM_ResunitPage from "./pages/_FM_ResunitPage";
import _FM_BerthPage from "./pages/_FM_BerthPage";
import _FM_BuilderPage from "./pages/_FM_BuilderPage";
import _FM_PaymentmethodPage from "./pages/_FM_PaymentmethodPage";
import _FM_ReschanelPage from "./pages/_FM_ReschanelPage";
import _FM_ServicePage from "./pages/_FM_ServicePage";
import _FM_OooresunitPage from "./pages/_FM_OooresunitPage";
import _FM_OooberthPage from "./pages/_FM_OooberthPage";
import _FM_ReservationPage from "./pages/_FM_ReservationPage";

if (process.env.REACT_APP_BRAND === 'felhomatrac') {
  require('primereact/resources/themes/saga-orange/theme.css');
  require('./assets/css/felhomatrac.css');
}
if (window.matchMedia("(prefers-color-scheme:dark)").matches === true) {
  // document.getElementById('root').classList.add('dark-theme')
  // require('primereact/resources/themes/bootstrap4-dark-blue/theme.css');
}

class App extends Component {

  constructor(props) {
    super(props);
    this.toastShow = this.toastShow.bind(this);

    window.App = this;

    this.state = {
      res: 'mobile'
    }
  };

  componentDidMount() {
    if (localStorage.getItem('language') == null) {
      localStorage.setItem('language', 'hu')
      window.App.forceUpdate()
    }
    if (localStorage.getItem('language') == 'hu') {
      i18next.changeLanguage('hu')
    } else if (localStorage.getItem('language') == 'en') {
      i18next.changeLanguage('en')
    }
    document.getElementById('root').classList.add(process.env.REACT_APP_BRAND)
  }

  componentDidUpdate() {

  }

  toastShow(severity, summary, detail, life, response) {
    if (response && response.data && response.data.code === 401) {
      this.logout()
    }
    if (response && response.data && response.data.message && document.getElementById("toast").innerHTML && document.getElementById("toast").innerHTML.includes(response.data.message)) {

    } else {
      if (response && response.data && response.data.data) {
        for (const key in response.data.data) {
          if (document.getElementById(key)) {
            document.getElementById(key).classList.add("p-invalid")
            document.getElementById(key).addEventListener('click', function handleClick() {
              document.getElementById(key).classList.remove("p-invalid");
            });
            if (document.getElementById(key).parentElement.parentElement.parentElement.querySelector('.p-error')) {
              document.getElementById(key).parentElement.parentElement.parentElement.querySelector('.p-error').remove()
            }
            document.getElementById(key).parentElement.parentElement.insertAdjacentHTML("afterend", "<small class=\"p-error\">" + response.data.data[key] + "</small>")
          }
        }
      }
      window.App.toast.show({severity: severity, summary: summary, detail: detail, life: life});
    }
  }

  changeLanguage(language) {
    if (language == 'hu') {
      localStorage.setItem('language', 'hu')
      i18next.changeLanguage('hu')
    } else if (language == 'en') {
      localStorage.setItem('language', 'en')
      i18next.changeLanguage('en')
    }
  }

  urlVars() {
    var vars = {};
    var parts = window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function (m, key, value) {
      vars[key] = value;
    });
    return vars;
  }

  transformQueryParams(lazyParams) {
    let query = '?'
    if (lazyParams.sortField) {
      query += '&sort=' + lazyParams.sortField.toLowerCase().replace(
        /[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase())
    }
    if (lazyParams.sortOrder) {
      if (lazyParams.sortOrder == 1) {
        query += '&direction=asc'
      }
      if (lazyParams.sortOrder == -1) {
        query += '&direction=desc'
      }
    }
    // if (lazyParams.filters) {
    //   Object.entries(lazyParams.filters).map(function (filter, index) {
    //     query += '&search[' + filter[0].toLowerCase() + ']=' + filter[1].value
    //   })
    // }
    if(lazyParams.is_active === 1 || lazyParams.is_active === 0) {
      query += '&search[is_active]=' + lazyParams.is_active
    }
    if(lazyParams.search) {
      query += '&search=' + lazyParams.search
    }
    if (lazyParams.rows) {
      query += '&item_per_page=' + lazyParams.rows
    }
    if (lazyParams.page) {
      query += '&page=' + Number(lazyParams.page + 1)
    }
    return(query)
  }

  logout() {
    // localStorage.removeItem('instanceId');
    // localStorage.removeItem('instanceName');
    localStorage.removeItem('userToken');
    window.location.replace('/#/');
    window.App.forceUpdate()
  }

  render() {
    return (
      <Router>
        <div className={process.env.REACT_APP_BRAND} id="app">
          <Favicon url={require("." + process.env.REACT_APP_FAVICON_FILE)}/>
          <Toast ref={(el) => this.toast = el} position="bottom-right" id={"toast"}/>
          {localStorage.getItem('userToken') == null ? (
            <Routes>
              <Route path="*" element={<LoginPage/>} exact/>
              <Route path="/registration" element={<RegistrationPage/>}/>
              <Route path="/password-reset" element={<PasswordresetRequestPage/>}/>
              <Route path="/password-set" element={<PasswordresetSetPage/>}/>
            </Routes>
          ) : (
            <>
              <BlockUI blocked={this.state.loading}>
                <NavbarComponent/>
                <div id="page">
                  <Routes>
                    <Route path="*" element={<ControlpanelPage/>} exact/>
                    <Route path="/control-panel" element={<ControlpanelPage/>}/>
                    <Route path="/user" element={<UsersPage/>}/>
                    <Route path="/profile" element={<ProfilePage />}/>
                    <Route path="/partner" element={<PartnerPage />}/>

                    {process.env.REACT_APP_BRAND === 'felhomatrac' &&
                    <Route path="/calendar" element={<_FM_CalendarPage/>}/>
                    }
                    {process.env.REACT_APP_BRAND === 'felhomatrac' &&
                    <Route path="/reservation" element={<_FM_ReservationPage/>}/>
                    }
                    {process.env.REACT_APP_BRAND === 'felhomatrac' &&
                    <Route path="/operator" element={<_FM_OperatorPage/>}/>
                    }
                    {process.env.REACT_APP_BRAND === 'felhomatrac' &&
                    <Route path="/accommodation" element={<_FM_AccommodationPage/>}/>
                    }
                    {process.env.REACT_APP_BRAND === 'felhomatrac' &&
                    <Route path="/building" element={<_FM_BuilingPage/>}/>
                    }
                    {process.env.REACT_APP_BRAND === 'felhomatrac' &&
                    <Route path="/category" element={<_FM_RescategoryPage/>}/>
                    }
                    {process.env.REACT_APP_BRAND === 'felhomatrac' &&
                    <Route path="/unit" element={<_FM_ResunitPage/>}/>
                    }
                    {process.env.REACT_APP_BRAND === 'felhomatrac' &&
                    <Route path="/berth" element={<_FM_BerthPage/>}/>
                    }
                    {process.env.REACT_APP_BRAND === 'felhomatrac' &&
                    <Route path="/builder" element={<_FM_BuilderPage/>}/>
                    }
                    {process.env.REACT_APP_BRAND === 'felhomatrac' &&
                    <Route path="/payment-method" element={<_FM_PaymentmethodPage/>}/>
                    }
                    {process.env.REACT_APP_BRAND === 'felhomatrac' &&
                    <Route path="/chanel" element={<_FM_ReschanelPage/>}/>
                    }
                    {process.env.REACT_APP_BRAND === 'felhomatrac' &&
                    <Route path="/service" element={<_FM_ServicePage/>}/>
                    }
                    {process.env.REACT_APP_BRAND === 'felhomatrac' &&
                    <Route path="/oooresunit" element={<_FM_OooresunitPage/>}/>
                    }
                    {process.env.REACT_APP_BRAND === 'felhomatrac' &&
                    <Route path="/oooberth" element={<_FM_OooberthPage/>}/>
                    }
                  </Routes>
                </div>
              </BlockUI>
            </>
          )}
        </div>
      </Router>
    );
  }
}

export default withTranslation('common')(App);
