import React, {useState, useEffect, useRef} from 'react';
import {withTranslation} from 'react-i18next';
import {Panel} from "primereact/panel";
import {Sidebar} from "primereact/sidebar";

import _FM_OooresunitListComponent from "../components/_FM_oooresunit/_FM_OooresunitListComponent";
import _FM_OooresunitFormComponent from "../components/_FM_oooresunit/_FM_OooresunitFormComponent";

const _FM_OooresunitPage = (props) => {

  const {t, i18n} = props;

  useEffect(() => {
    document.title = t('ooooooresunit') + ' - ' + process.env.REACT_APP_TITLE;
  }, []);

  return (
    <div className="_FM_OooresunitPage p-component p-m-lg-4">
      <Panel>
        <_FM_OooresunitListComponent />
      </Panel>
      <Sidebar position="right" visible={window.App.state.sidebarOooresunitForm} closeOnEscape={true}
               style={{width: '780px'}}
               onHide={() => window.App.setState({
                 sidebarOooresunitForm: false,
               })}>
        <_FM_OooresunitFormComponent />
      </Sidebar>
    </div>
  )
}
export default withTranslation('common')(_FM_OooresunitPage);
